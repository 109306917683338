import React from "react";
import Balance from "./components/Balance";
import DailyTraffic from "./components/DailyTraffic";
import MostVisited from "./components/MostVisited";
import OverallRevenue from "./components/OverallRevenue";
import ProfitEstimation from "./components/ProfitEstimation";
import ProjectStatus from "./components/ProjectStatus";
import YourCard from "./components/YourCard";
import YourTransfers from "./components/YourTransfers";
import Widget from "components/widget/Widget";
//import Widget from "./components/widget/Widget";
import { tableColumnsMostVisited } from "./variables/tableColumnsMostVisited";
import tableDataMostVisited from "./variables/tableDataMostVisited";
import { auth } from "../../../../firebase/firebase";
import { useSelector } from "react-redux";
import { getUser,AIRecommendation, fetchEventUserIDs, shuffleUsers } from "../../../../firebase/firebase-calls";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";


import TimelineItem from "components/dataDisplay/TimelineItem";
import { stringSimilarity } from "string-similarity-js";


import OpenAI from "openai";


//export excel information
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';






const Dashboard = () => {

  const { allCircles } = useSelector(state => (state.allCircles
  ));
  const {allUsers} = useSelector(state => (state.allUsers))
  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const openai = new OpenAI({ apiKey: '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', dangerouslyAllowBrowser: true });

  const [isLoading, setIsLoading] = useState(false);
  const [matches, setMatches] = useState({});
  const [insights, setInsights] = useState({});

  useEffect(() => {
    if (!authToken) {
      navigate('/auth/sign-in/centered');
    }
  }, [authToken, navigate]);

  const similarityThreshold = 0;


  const WorkflowSteps = {
    INIT: "Initializing",
    QUERYING: "Querying User Data",
    PROCESSING: "Processing Matches",
    FETCHING_INSIGHTS: "Fetching Insights",
    COMPLETED: "Completed"
  };

  const [currentStep, setCurrentStep] = useState(WorkflowSteps.INIT);
  const [progress, setProgress] = useState(0);
  const [matchmakingDetails, setMatchmakingDetails] = useState([]);



// List of common stopwords
const stopwords = [
  'a', 'about', 'above', 'after', 'again', 'against', 'all', 'am', 'an', 'and', 'any', 'are', 'aren\'t', 'as', 'at', 
  'be', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'by', 
  'can\'t', 'cannot', 'could', 'couldn\'t', 
  'did', 'didn\'t', 'do', 'does', 'doesn\'t', 'doing', 'don\'t', 'down', 'during', 
  'each', 
  'few', 'for', 'from', 'further', 
  'had', 'hadn\'t', 'has', 'hasn\'t', 'have', 'haven\'t', 'having', 'he', 'he\'d', 'he\'ll', 'he\'s', 'her', 'here', 'here\'s', 'hers', 'herself', 'him', 'himself', 'his', 'how', 'how\'s', 
  'i', 'i\'d', 'i\'ll', 'i\'m', 'i\'ve', 'if', 'in', 'into', 'is', 'isn\'t', 'it', 'it\'s', 'its', 'itself', 
  'let\'s', 
  'me', 'more', 'most', 'mustn\'t', 'my', 'myself', 
  'no', 'nor', 'not', 
  'of', 'off', 'on', 'once', 'only', 'or', 'other', 'ought', 'our', 'ours', 'ourselves', 'out', 'over', 'own', 
  'same', 'shan\'t', 'she', 'she\'d', 'she\'ll', 'she\'s', 'should', 'shouldn\'t', 'so', 'some', 'such', 
  'than', 'that', 'that\'s', 'the', 'their', 'theirs', 'them', 'themselves', 'then', 'there', 'there\'s', 'these', 'they', 'they\'d', 'they\'ll', 'they\'re', 'they\'ve', 'this', 'those', 'through', 'to', 'too', 
  'under', 'until', 'up', 
  'very', 
  'was', 'wasn\'t', 'we', 'we\'d', 'we\'ll', 'we\'re', 'we\'ve', 'were', 'weren\'t', 'what', 'what\'s', 'when', 'when\'s', 'where', 'where\'s', 'which', 'while', 'who', 'who\'s', 'whom', 'why', 'why\'s', 'with', 'won\'t', 'would', 'wouldn\'t', 
  'you', 'you\'d', 'you\'ll', 'you\'re', 'you\'ve', 'your', 'yours', 'yourself', 'yourselves'
];

// Function to remove stopwords from a text string
const removeStopwords = (text) => {
    return text
        .split(' ')
        .filter(word => !stopwords.includes(word.toLowerCase()))
        .join(' ');
};

const queryUserData = (allUsers) => {
  let userMatches = {};

  for (const user of allUsers) {
    // Skip users without a fullName or userID
    if (!user.fullName || !user.userID) {
      continue;
    }

    userMatches[user.userID] = allUsers
      .filter(otherUser => otherUser.userID !== user.userID && otherUser.fullName && otherUser.userID)
      .map(otherUser => {
          // Count the number of filled fields for both users
          const filledFieldsUser = [user.bio, user.networkingGoals, user.interests, user.dedication, user.major].filter(field => field).length;
          const filledFieldsOtherUser = [otherUser.bio, otherUser.networkingGoals, otherUser.interests, otherUser.dedication, otherUser.major].filter(field => field).length;

          // If combined filled fields are less than 6, skip comparison
          if (filledFieldsOtherUser < 2) {
              return null;
          }

          const userCombinedText = removeStopwords(`${user.bio} ${user.networkingGoals} ${user.interests} ${user.dedication}`);
          const otherUserCombinedText = removeStopwords(`${otherUser.bio} ${otherUser.networkingGoals} ${otherUser.interests} ${otherUser.dedication}`);

          // Calculate the similarity score
          const similarityScore = stringSimilarity(userCombinedText, otherUserCombinedText);
          return similarityScore >= 0.35 ? { id: otherUser.userID, score: similarityScore } : null;
      })
      .filter(match => match) // Filter out nulls
      .map(match => ({ id: match.id, score: match.score })); // Include the similarity score in the result
  }

  console.log('Final matches with scores:', userMatches);
  return userMatches;
};


const getMatchInsights = async (user1, user2) => {
  const prompt = `Given your expertise in identifying potential collaborations, you're advising Entrepreneur 1 about teaming up with Entrepreneur 2 for a new startup. Consider the following profiles:

  - Entrepreneur 1: ${JSON.stringify(user1, null, 2)}
  - Entrepreneur 2: ${JSON.stringify(user2, null, 2)}
  
  Provide detailed advice on why this collaboration could be beneficial. Focus on their complementary skills, shared interests, and mutual benefits. Key points should be in short bullet points for clarity. Be very descriptive and analytical but concise, and conclude with a summary. Avoid mentioning similarity scores or entrepreneur numbers. Use their names sparingly.
  
  Example:
  "Main reason why user 1 should work with user 2:
  
  **Complementary Skills**:
  - Entrepreneur 1's expertise in [Skill/Area] pairs well with Entrepreneur 2's knowledge in [Skill/Area].
  - Entrepreneur 2's experience in [Skill/Area] complements Entrepreneur 1's abilities in [Skill/Area].
  
  **Shared Interests**:
  - Both are passionate about [Shared Interest], fostering a strong foundation for collaboration.
  
  **Mutual Benefits**:
  - Together, they can leverage their diverse skills to effectively address [Startup Focus/Challenge].

  **What to say when reaching out**:
  -What should user 1 linkedin message say to user 2
  **In summary**, detailed description on why they should network"
  `;

// Code for calling the OpenAI API with this prompt...

    try {
    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: "You are a analytical AI matchmaking assistant."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      model: "gpt-3.5-turbo-1106",

      max_tokens: 4096
    });
      console.log(completion.choices[0]?.message?.content)
      return completion.choices[0]?.message?.content || "";
} catch (error) {
    console.error('Error getting match insights:', error);
    return '';
  }
};

function matchmakingScore(similarityScore) {
  const minSimilarity = 0.34;
  const maxSimilarity = 0.52;
  const minScore = 55;
  const maxScore = 100;

  if (similarityScore < minSimilarity) {
      return minScore;
  } else if (similarityScore > maxSimilarity) {
      return maxScore;
  } else {
    return Math.round(((similarityScore - minSimilarity) / (maxSimilarity - minSimilarity)) * (maxScore - minScore) + minScore);
  }
}

const processMatches = async () => {
  setIsLoading(true);
  setCurrentStep(WorkflowSteps.QUERYING);
  setProgress(25);
  setMatchmakingDetails([...matchmakingDetails, 'Querying User Data...']);

  const matchedUsers = queryUserData(allUsers);
  setProgress(50);
  setCurrentStep(WorkflowSteps.PROCESSING);
  setMatchmakingDetails([...matchmakingDetails, 'Processing Matches...']);

  let insightsData = {};

  for (const userId in matchedUsers) {
    if (matchedUsers[userId].length === 0) continue;

    const user = allUsers.find(u => u.userID === userId);
    insightsData[userId] = [];

    for (const match of matchedUsers[userId]) {
      const matchUser = allUsers.find(u => u.userID === match.id);
      setMatchmakingDetails([...matchmakingDetails, `Matching ${user.fullName} with ${matchUser.fullName}, Score: ${match.score}`]);

      const userText = `Name: ${user.fullName}, Major: ${user.major || 'Not specified'}, Bio: ${removeStopwords(user.bio || '')}, Networking Goals: ${removeStopwords(user.networkingGoals || '')}, Interests: ${removeStopwords(user.interests || '')}, Dedication: ${removeStopwords(user.dedication || '')}`;
      const matchUserText = `Name: ${matchUser.fullName}, Major: ${matchUser.major || 'Not specified'}, Bio: ${removeStopwords(matchUser.bio || '')}, Networking Goals: ${removeStopwords(matchUser.networkingGoals || '')}, Interests: ${removeStopwords(matchUser.interests || '')}, Dedication: ${removeStopwords(matchUser.dedication || '')}, Similarity score: ${match.score}`;

      const insight = await getMatchInsights(userText, matchUserText);
      insightsData[userId].push({ mw: match.id, sc: matchmakingScore(match.score), rsn: insight });
    }
  }

  setMatches(matchedUsers);
  setInsights(insightsData);
  setProgress(100);
  setCurrentStep(WorkflowSteps.COMPLETED);
  setMatchmakingDetails([...matchmakingDetails, 'Matchmaking Completed!']);
  console.log(insightsData);
  AIRecommendation(insightsData)
  setIsLoading(false);
};

//Code needed for the shuffling algorithm
const [numberOfTables, setNumberOfTables] = useState(0);
const [numberOfRounds, setNumberOfRounds] = useState(0);
const [studentIDs, setStudentIDs] = useState([]);
const [tableAssignments, setTableAssignments] = useState([]);
const [peoplePerTable, setPeoplePerTable] = useState(4); // Default to 4 or any number you prefer

useEffect(() => {
  fetchEventUserIDs().then(fetchedUserIDs => {
    setStudentIDs(fetchedUserIDs);
  });
}, []);


const generateTableAssignments = () => {
  // Clear previous assignments
  setTableAssignments([]);

  let rounds = [];
  let userTableAssignments = {}; // Store userId to table assignments

  for (let round = 0; round < numberOfRounds; round++) {
    // Shuffle student IDs
    let shuffledStudents = [...studentIDs].sort(() => 0.5 - Math.random());

    // Create a map for the round's assignments
    let roundAssignments = new Map();

    for (let i = 0; i < shuffledStudents.length; i++) {
      const tableIndex = i % numberOfTables;
      if (!roundAssignments.has(tableIndex)) {
        roundAssignments.set(tableIndex, []);
      }
      roundAssignments.get(tableIndex).push(shuffledStudents[i]);

      // Record the table assignment for the student
      if (!userTableAssignments[shuffledStudents[i]]) {
        userTableAssignments[shuffledStudents[i]] = [];
      }
      userTableAssignments[shuffledStudents[i]].push({ round: round + 1, table: tableIndex + 1 });
    }

    rounds.push(roundAssignments);
  }

  setTableAssignments(rounds);
  console.log(userTableAssignments); // Log the user to table mapping
  shuffleUsers(userTableAssignments)
  return userTableAssignments; // Return the mapping
};


// Example usage
// You can now use `userAssignments` in your AIRecommendation call or other logic


// Example usage
// const userAssignments = generateTableAssignments();
// You can now use `userAssignments` in your AI
// const userIDsArray = studentIDs.map(entry => entry.userID);


// const checkInData = allUsers.filter(user=>studentIDs.includes(user.userID)).map(user => {
  const checkInData = allUsers
  .filter(user => studentIDs.includes(user.userID))
  .map(user => {
    return {
      // userID: user.userID,
      fullName: user.fullName || '',
      email: user.email ? user.email : '',
      major: user.major ? user.major : '',
      phone: user.phone ? user.phone : '',
      role: user.role ? user.role : '',
      // bio: user.bio ? user.bio : '',
      // CareerGoals: user.dedication ? user.dedication : '',
      // instagram: user.instagram ? user.instagram : '',
      // interests: user.interests ? user.interests : '',
      // linkedIn: user.linkedIn ? user.linkedIn : '',
      // networkingGoal: user.networkingGoal ? user.networkingGoal : '',
    

      // Add more attributes as needed
    };
  });


const exportToExcel = (checkInData) => {
  const ws = XLSX.utils.json_to_sheet(checkInData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "CheckIns");

  // Create a temporary file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const file = new Blob([wbout], { type: 'application/octet-stream' });

  // Save the file
  saveAs(file, 'CheckInData.xlsx');
};


  
  useEffect(
    () => {
      if (!authToken) { navigate("/auth/sign-in/centered") }
    },
    // eslint-disable-next-line
    [authToken]
  );
  // 
  const currentUser = auth?.currentUser

  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <div className="mt-16 md:mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Students"}
              subtitle={allUsers.length}
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Teams"}
              subtitle={allCircles.length}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Total Check-ins"}
              subtitle={studentIDs.length}
            />
             <div className="mt-5 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">AI Matchmaking</h2>
            {isLoading && (
      <>
        <div>Current Step: {currentStep}</div>
        <div style={{ width: '100%', backgroundColor: '#ddd' }}>
          <div style={{ height: '24px', width: `${progress}%`, backgroundColor: 'green' }}></div>
        </div>
      </>
    )}

            <div>
              <div className="execute-workflow-button-container">
                <button 
                  onClick={processMatches} 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  disabled={isLoading}
                >
            {isLoading ? 'Processing...' : 'Execute AI Matchmaking'}
          </button>
          {matchmakingDetails.length > 0 && (
        <div className="matchmaking-details">
          <h3>Matchmaking Progress:</h3>
          <ul>
            {matchmakingDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
      )}
                  </div>

        </div>
    </div>
    <div>
    <div className="flex flex-col items-center justify-center p-4">
  <input 
    type="number" 
    placeholder="Number of Tables" 
    value={numberOfTables} 
    onChange={(e) => setNumberOfTables(e.target.value)} 
    className="mt-2 w-full rounded border border-gray-300 p-2 text-lg focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
  />
  <input 
    type="number" 
    placeholder="Number of Rounds" 
    value={numberOfRounds} 
    onChange={(e) => setNumberOfRounds(e.target.value)} 
    className="mt-2 w-full rounded border border-gray-300 p-2 text-lg focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
  />
  <button 
    onClick={generateTableAssignments}
    className="mt-4 rounded bg-blue-500 py-2 px-4 text-lg text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
  >
    Generate Assignments
  </button>
</div>


  {/* Input for student IDs could be a text area, a file upload, or another method */}
  <div>
  {tableAssignments.map((round, roundIndex) => (
  <div key={roundIndex}>
    <h3>Round {roundIndex + 1}</h3>
    <ul>
      {Array.from(round.entries()).map(([tableIndex, students]) => (
        <li key={tableIndex}>
          <strong>Table {tableIndex + 1}</strong>:
          <ul>
            {students.map(student => <li
key={student}>{student}</li>)}
</ul>
</li>
))}
</ul>


  </div>
))}
</div>

      
</div>
<button onClick={() => exportToExcel(checkInData)}>Export to Excel</button>


           
         
       



          </div>
          {/* <div className="mt-10 md:mt-5 mb-5 grid grid-cols-6 gap-5">
            <div className="col-span-6 h-full w-full rounded-xl 3xl:col-span-4">
              <OverallRevenue />
            </div>
            <div className="col-span-6 w-full 3xl:col-span-2">
              <Balance />
            </div>
          </div>
          <div className="mt-5 grid w-full grid-cols-6 gap-5">
            <div className="col-span-6 md:col-span-3 3xl:col-span-2">
              <DailyTraffic />
            </div>
            <div className="col-span-6 md:col-span-3 3xl:col-span-2">
              <ProjectStatus />
            </div>
            <div className="col-span-6 3xl:col-span-2">
              <ProfitEstimation />
            </div>
          </div>
          <div className="mt-5 grid w-full grid-cols-6 gap-5">
            <div className="col-span-6 3xl:col-span-2">
              <YourTransfers />
            </div>
            <div className="col-span-6 3xl:col-span-4">
              <MostVisited
                tableData={tableDataMostVisited}
                columnsData={tableColumnsMostVisited}
              />
            </div>
          </div> */}
        </div>
      </div>

      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

      {/* right section */}
      <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <YourCard />
      </div>
    </div>
  );
};

export default Dashboard;
