import ChairDef from "assets/img/ecommerce/ChairDef.png";
import Delete from "./components/Delete";
import Details from "./components/Details";
import DropeZone from "./components/DropZone";
import Information from "./components/Information";
import Teams from "./components/Teams";
const SettingsProduct = () => {
  return (
    <div className="mt-4 grid h-full w-full grid-cols-2 gap-5 xl:mt-3">
      {/* left side */}
      <div className="col-span-2 flex h-full w-full flex-col gap-5 xl:col-span-1">
        {/* Chair */}
        <div className="flex w-full items-center justify-center rounded-[20px] bg-white bg-cover bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
        <Information />

        </div>
      </div>
      {/* }
      <div className="col-span-2 flex h-full w-full flex-col gap-5 xl:col-span-1">
        <DropeZone />
        <Teams />
      </div>
      */}
    </div>
  );
};

export default SettingsProduct;
