import React from "react";
import { Link, useLocation } from "react-router-dom";

export function SidebarLinks(props) {
  let location = useLocation();
  const { routes } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const renderLink = (route, key) => {
    if (route.isExternal) {
        return (
            <a href={route.path} target="_blank" rel="noopener noreferrer" key={key}>
                {renderContent(route, key)}
            </a>
        );
    } else {
        return (
            <Link to={route.layout + route.path} key={key}>
                {renderContent(route, key)}
            </Link>
        );
    }
};

  const renderContent = (route, key) => (
    <div className="relative mb-2 flex hover:cursor-pointer">
      <li className="my-[3px] flex cursor-pointer px-8" key={key}>
        <span
          className={`mt-0.5 ${
            activeRoute(route.path) === true
              ? "font-bold text-brand-500 dark:text-white text-lg" // added text-lg for larger text
              : "text-gray-600 text-lg"  // added text-lg for larger text
          }`}
        >
          {route.icon ? route.icon : null}
        </span>
        <span
          className={`ml-2 flex text-base ${
            activeRoute(route.path) === true
              ? "font-bold text-navy-700 dark:text-white"
              : "text-gray-600"
          }`}
        >
          {route.name}
        </span>
      </li>
      {activeRoute(route.path) ? (
        <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500" />
      ) : null}
    </div>
  );

  return (
    <div>
      {routes.map((route, key) => renderLink(route, key))}
    </div>
  );
}

export default SidebarLinks;
