import SearchTableUsers from './components/SearchTableUsersOverview';
import tableDataUsersOverview from './variables/tableDataUsersOverview.json';
import { columnsDataUsersOverview } from './variables/columnsDataUsersOverview';
import Card from 'components/card';
import { useSelector } from 'react-redux';


import { useNavigate } from 'react-router-dom';
import { getUser, profileUpdate } from "../../../../../firebase/firebase-calls";
import { useState, useEffect } from 'react';
import {auth} from "../../../../../firebase/firebase"

const UserOverview = () => {

  const transformUserData = (allUsers, userMatches) => {
    // Create a map of allUsers for easy lookup
    const usersMap = allUsers.reduce((map, user) => {
      map[user.userID] = user;
      return map;
    }, {});
  
    // Transform each match in userMatches
    const transformedMatches = userMatches.map(match => {
      const matchedUser = usersMap[match.mw] || {};
  
      return {
        name: [matchedUser.fullName, matchedUser.profilePic, matchedUser.userID],
        linkedIn: matchedUser.linkedIn || '',
        reason: match.rsn,
        score: match.sc,
        interests: matchedUser.interests,
        major: matchedUser.major
      };
    });
    return transformedMatches;
  };
  
  


  //get user - 
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [transformedData, setTransformedData] = useState()
  const { allUsers } = useSelector((state) => state.allUsers);


  const authToken = localStorage.getItem('authToken');
  if (!authToken) { navigate('/auth/sign-in/centered'); }
  



  // console.log("Auth Token in User Profile: " + authToken);
  const currentUser = auth?.currentUser;

  useEffect(() => {
    if (currentUser) {
      getUser(currentUser, setUserData)
        .catch(error => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [currentUser]);



const ScoreLabel = ({ score }) => {
  const scoreColor = score > 70 ? 'text-green-500' : 'text-yellow-500';
  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (score / 100) * circumference;

  return (
    <div className={`flex justify-center items-center ${scoreColor}`}>
      <svg width="48" height="48">
        <circle
          cx="24"
          cy="24"
          r={radius}
          fill="transparent"
          stroke={score > 70 ? '#10B981' : '#F59E0B'}
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 24 24)"
        />
        <text
          x="50%"
          y="50%"
          fill="currentColor"
          fontSize="1.25rem" // Increase font size as needed
          dy=".3em"
          textAnchor="middle"
        >
          {score}
        </text>
      </svg>
    </div>
  );
};
const formatText = (text) => {
  const boldPattern = /\*\*(.*?)\*\*/g;
  let formattedText = text.split('\n').map((line, index) => {
    const parts = line.split(boldPattern);

    return (
      <div key={index} style={{ paddingLeft: '20px' }}> {/* Indent each line */}
        {parts.map((part, index) => 
          index % 2 === 1 ? <strong key={index}>{part}</strong> : part // Bold the text within asterisks
        )}
      </div>
    );
  });

  return <>{formattedText}</>;
};

const MatchedUserItem = ({ user }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/admin/main/profile/overview/${user.name[2]}`); // Assuming user.name[2] is the userID
  };

  return (
    <div className="flex space-x-4 bg-white p-4 rounded-lg shadow-md my-2 cursor-pointer" onClick={handleCardClick}>
  <div className="flex flex-col items-center w-1/4 min-w-[6rem]"> {/* Add a minimum width */}
    <img src={user.name[1]} alt={user.name[0]} className="h-24 w-24 rounded-full object-cover" />
    <div className="mt-2 text-center w-full"> {/* Ensure this div takes the full width of its parent */}
      <p className="text-sm font-medium text-gray-700">{user.interests}</p> {/* Styled interests below the image */}
    </div>
  </div>
  <div className="flex-grow">
    <h3 className="text-xl font-semibold">{user.name[0]}</h3>
    <div className="mt-2 inline-block bg-blue-100 text-blue-700 px-3 py-1 rounded-full shadow">
      <span className="text-sm font-medium">{user.major}</span>
    </div>
    <p className="text-sm text-gray-700">{formatText(user.reason)}</p>
  </div>
  <ScoreLabel score={user.score} />
</div>

  );
};

const MatchedUsersList = ({ users }) => {
  // Sort users by score in descending order
  const sortedUsers = [...users].sort((a, b) => b.score - a.score);

  return (
    <div className="space-y-2">
      {sortedUsers.map((user) => (
        <MatchedUserItem key={user.id} user={user} />
      ))}
    </div>
  );
};
useEffect(() => {

if (Object.keys(userData).length > 0) {
  if (userData.userMatch) {
    // userData is now updated, so we can transform the data
    const transformed = transformUserData(allUsers, userData.userMatch);
    setTransformedData(transformed);
    setLoading(false); // Set loading to false after data transformation
  } else {
    setLoading(false); // Set loading to false as there is no userMatch data
  }
}
}, [userData, allUsers]);

if (loading) {
return <div>Loading...</div>; // Or any other loading indicator
}
const betaMessage = () => (
  <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 m-5 rounded-lg shadow-md flex flex-col items-center justify-center space-y-4">
      <h2 className="text-2xl font-semibold">🚀 Exciting Features Ahead! 🌟</h2>
      <p className="text-md">
        🤩 <strong>You're just a step away from something amazing!</strong> Our beta features are currently being tested by a select group of users.
      </p>
      <p className="text-md">
        💫 <strong>Stay tuned</strong> and get ready to experience the magic! ✨
      </p>
    </div>
);


return (
  <div className="w-full">
  {/* Enhanced Title and Instructions */}
  <div className="text-center py-6 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg shadow-xl mx-4 my-4">
    <h1 className="text-4xl font-bold text-white">🚀 AI Networking 🌟</h1>
    <p className="text-md mt-3 mr-3 ml-3 text-white">
      Discover and connect with fellow innovators and entrepreneurs! 🤝
    </p>
  </div>

  <div className="m-4 p-4 bg-white rounded-lg shadow-md">
    <p className="text-lg text-gray-700">
      Explore matched users based on shared interests and skills. Click on a user card to view their full profile and explore collaboration opportunities. 💼🤖 
    </p>
  </div>

  {/* Main Content */}
  <Card extra={'w-full h-full mt-5'}>
    {!loading && transformedData ? (
      <MatchedUsersList users={transformedData} />
    ) : (
      betaMessage()
    )}
  </Card>
</div>
);
};

export default UserOverview;