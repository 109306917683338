import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";

//for banner
import banner from "assets/img/profile/banner.png";
import instagram from "assets/svg/instagram-logo.jpg"
import linkedIn from "assets/svg/LinkedIn_icon.svg.png"

import Card from "components/card";

//firebase data calls
import {getUser, userViewCount, likeProfile} from "../../../../../firebase/firebase-calls"
import { useParams,useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import {auth} from "../../../../../firebase/firebase"




const ProfileOverview = () => {
  const { profileID } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  //set the field UID for getUser to fetch data into other User
  const [hasLiked, setHasLiked] = useState(false);


  const authToken = localStorage.getItem('authToken');
  if (!authToken) { navigate('/auth/sign-in/centered'); }
  
  // console.log("Auth Token in User Profile: " + authToken);
  const currentUser = auth?.currentUser;

//   useEffect(() => {
//     if (currentUser) {
//       getUser(currentUser, setUserData)
//         .catch(error => {
//           console.error("Error fetching user data:", error);
//         });
//     }
//   }, [currentUser]);


//     useEffect(() => {
//         if (!userData.uid) {
//             getUser({"uid": profileID}, setUserData);
//         }
//         userViewCount(userData?.uid);
//     }, [userData, profileID]);

   
//     useEffect(() => {
//       if (userData && userData.likes && currentUser) {
//           // Check if the current user's ID is in the likes array
//           setHasLiked(userData.likes.includes(currentUser.uid));
//       }
//   }, [userData, currentUser]);
//   const handleLikeProfile = async () => {
//     if (!hasLiked) {
//         await likeProfile(profileID, currentUser?.uid); // Replace "current_user_id" with the actual current user's ID
//         setHasLiked(true);
//         // Additional logic if needed to reflect this change in userData
//     }
// };



//   useEffect(
//     () => {
//       if (userData.length == 0) {
//         getUser({"uid": profileID}, setUserData);
//         console.log("User Fetched!: "+ userData)
//         userViewCount(userData?.uid);
//       }
     
//     },
     
//     // eslint-disable-next-line
//     [userData]
//   );
useEffect(() => {
  // Ensure currentUser is defined and has a uid before calling getUser
  if (currentUser && currentUser.uid) {
    getUser(currentUser, setUserData)
      .catch(error => {
        console.error("Error fetching user data:", error);
      });
  }
}, [currentUser]);

useEffect(() => {
  // Ensure profileID is defined and userData.uid is not set before calling getUser
  if (profileID && !userData.uid) {
    getUser({"uid": profileID}, setUserData)
      .then(() => userViewCount(userData?.uid))
      .catch(error => console.error("Error fetching user data:", error));
  }
}, [profileID, userData.uid]);

  // Banner controls what the profile card looks like
  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="flex flex-col col-span-4 lg:!mb-0">
        <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
            {/* Background and profile */}
            <div
              className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
              style={{ backgroundImage: `url(${banner})` }}
            >
              <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                <img className="h-full w-full rounded-full object-cover" src={userData?.profilePic } alt="ne" />
              </div>
            </div>

            {/* Name and position */}
            <div className="mt-16 flex flex-col items-center">
              <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              {userData?.fullName}
              </h4>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {userData?.major}
                </p>
              <div className="flex items-center mt-5">  {/* adjusted to flex container for proper alignment */}
                {userData?.linkedIn && (
                  <a href={userData.linkedIn} target="_blank" rel="noopener noreferrer" className="mr-2">
                    <img src={linkedIn} alt="LinkedIn Logo" width="50" height="50" />  {/* LinkedIn logo */}
                  </a>
                )}
                {userData?.instagram && (
                  <a href={userData.instagram} target="_blank" rel="noopener noreferrer">
                    <img src={instagram} alt="Instagram Logo" width="65" height="65" />  {/* Instagram logo */}
                  </a>
                )}
                
              </div>
              <div className="mt-2 mb-8 w-full">
              <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                Bio
              </h4>
              <p className="mt-2 px-2 text-base text-gray-600">
                {userData?.bio}
              </p>
            </div>
            </div>

            <div className="mt-4">
            {/* <button 
                onClick={handleLikeProfile} 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                {hasLiked ? " Save" : "+ Like Profile"}
            </button> */}
        </div>
          </Card>
          
         
        </div>

        <div className="col-span-5 lg:col-span-8 lg:!mb-0">
        <Card extra={"w-full h-full p-3"}>
            {/* Header */}
            
            {/* Cards */}
            <div className="grid grid-cols-2 gap-4 px-2">
              

              

            </div>
            <div className="grid grid-cols-1 gap-4 px-2 mt-4">
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Networking Goals</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                  {userData?.networkingGoal}
                  </p>
              </div>
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Career Goals</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                  {userData?.dedication}
                  </p>
              </div>
              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Interests</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                  {userData?.interests}
                  </p>
              </div>
              <div className="mt-4">
            <Project userID={profileID}/>
          </div>
            </div>

            


          </Card>
        </div>


      </div>

    </div>
  );
};

export default ProfileOverview;
