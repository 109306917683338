import { login, updateDP } from "features/user/userSlice";
import { signInWithEmailAndPassword, signInWithPhoneNumber, updateProfile,RecaptchaVerifier } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { auth, db, storage } from "./firebase";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment, 
  serverTimestamp
} from "firebase/firestore";

const allPostsCollection = collection(db, "allPosts");
const usersCollection = collection(db, "users");
const allCircles = collection(db, "allCircles");
const allFeed = collection(db, "allFeed");




const date = new Date().toLocaleDateString("en-IN", {
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

export const userLogin = async (
  email,
  password,
  dispatch,
  lastLocation,
  navigate
) => {
  try {
    const loader = toast.loading("Signing you in..");
    const userAuth = await signInWithEmailAndPassword(auth, email, password);
    dispatch(
      login({
        email: userAuth.user.email,
        uid: userAuth.user.uid,
        displayName: userAuth.user.displayName,
        photoURL: userAuth.user.photoURL,
      })
    );
    localStorage.setItem("authToken", userAuth.user.accessToken);
    toast.success(`Welcome back, ${userAuth.user.displayName}!`, {
      id: loader,
    });
    navigate(lastLocation);
  } catch (error) {
    toast.error("Couldn't log you in!");
    console.log(error.message);
  }
};
export const createProfile = async (currentUser) => {
 
    await setDoc (
      doc(collection(db, "users"), currentUser.uid), {
        userID: currentUser.uid,
        fullName: currentUser.displayName,
        profilePic: currentUser.photoURL,
        bio:"",
        major: "",
        linkedIn: "",
        instagram: "",
        interests: "",
        networkingGoal: "",
        dedication: "",
        email: currentUser.email,
        org: "Manifest"
      },
      { merge: true }
    );
  };

  export const likeProfile = async (otherProfileID, currentID) => {
    try {
        // Update 'likes' in both users' documents
        await updateDoc(doc(collection(db, "users"), otherProfileID), {
            likes: arrayUnion(currentID)
        }, { merge: true });

        await updateDoc(doc(collection(db, "users"), currentID), {
            likes: arrayUnion(otherProfileID)
        }, { merge: true });

        // Add a new document in 'profileLikes' collection
        await addDoc(collection(db, "profileLikes"), {
            likedBy: currentID,
            likedProfile: otherProfileID,
            timestamp: new Date() // Optional: store the time of the like
        });

    } catch (error) {
        console.error("Error in liking profile: ", error);
    }
};


export const updateEvent = async (userData) => {
  const eventDocRef = doc(db, "events", "0MPEI5134DivfCgHpFkz"); // Reference to your document

  const checkInData = {
    fullName: userData.fullName,
    userID: userData.userID,
    timestamp: new Date() // Create a JavaScript Date object for the timestamp
  };

  try {
    await updateDoc(eventDocRef, {
      // Adding to the checkIn array with the new checkInData including the timestamp
      checkIn: arrayUnion(checkInData)
    });

    console.log("Event updated successfully");
  } catch (error) {
    console.error("Error updating event: ", error);
  }
};
  // export const AIRecommendation = async (userJSON) => {
  //   console.log("AI recommendation: " + userJSON);

  //   const matches = userJSON

  //   for (const userID in matches) {
  //       const userMatches = matches[userID];
 
  //          try {
  //         await setDoc (
  //           doc(collection(db, "recommendations"), userID),
  //           { userMatches }
  //         );
  //       } catch (error) {
  //         console.error("Error writing document: ", error);
  //       }
  //    };
  //   };

  export const AIRecommendation = async (userJSON) => {
    console.log(userJSON);
    const matches = userJSON;
    console.log("AI Recommendation called");

    for (const userID in matches) {
        const userMatches = matches[userID];

        // Check if userMatches is not undefined and not empty
        if (userMatches && userMatches.length > 0) {
            try {
                // Update user's matches
                await setDoc(
                    doc(collection(db, "users"), userID),
                    { userMatch: userMatches},
                    { merge: true } // Merges with existing data

                );

                // Write each match into the 'recommendations' collection
                for (const match of userMatches) {
                    await addDoc(collection(db, "recommendations"), {
                        userID: userID,
                        matchedUserID: match, // Assuming 'match' holds the ID of the matched user
                        timestamp: serverTimestamp(), // Adds a server-side timestamp
                        organization: "NVC"
                    });
                }
            } catch (error) {
                console.error(`Error writing document for user ${userID}: `, error);
            }
        } else {
            console.warn(`No matches or undefined data for user ${userID}`);
        }
    }
};
export const fetchEventUserIDs = async () => {
  const eventUserIDs = [];

  console.log("Starting to fetch Event User IDs");

  try {
    console.log("Accessing the 'events' collection");
    const eventsCollectionRef = collection(db, "events");

    console.log("Fetching a specific document from the 'events' collection");
    const docRef = doc(eventsCollectionRef, "0MPEI5134DivfCgHpFkz");
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      console.log("Document found with ID:", docSnapshot.id);
      const eventData = docSnapshot.data();

      if (Array.isArray(eventData.checkIn)) {
        console.log("Found 'checkIn' field in document:", docSnapshot.id);
        eventData.checkIn.forEach(checkInEntry => {
          if (checkInEntry.userID) {
            console.log("Adding userID:", checkInEntry.userID);
            eventUserIDs.push(checkInEntry.userID);
          }
        });
      }
    } else {
      console.log("No document found with the specified ID");
    }

    console.log("Fetched Event User IDs:", eventUserIDs);
    return eventUserIDs;
  } catch (error) {
    console.error("Error fetching event user IDs:", error);
    return [];
  }
};


// Example usage

export const shuffleUsers = async (userJSON) => {
  console.log(userJSON);
  const userAssignments = userJSON;
  console.log("Shuffle Users called");

  for (const userID in userAssignments) {
    const userTableAssignments = userAssignments[userID];

    // Check if userTableAssignments is not undefined and not empty
    if (userTableAssignments && userTableAssignments.length > 0) {
      try {
        // Update user's table assignments
        await setDoc(
          doc(collection(db, "users"), userID),
          { shuffle: userTableAssignments },
          { merge: true } // Merges with existing data
        );
      } catch (error) {
        console.error(`Error writing document for user ${userID}: `, error);
      }
    } else {
      console.warn(`No table assignments or undefined data for user ${userID}`);
    }
  }
};


    // try {
    //   for (let i = 368; i <= 600; i++) {
    //     const docId = i.toString(); // Assuming the IDs are just the numbers as strings
    //     const docRef = doc(db, "users", docId);
    //     await deleteDoc(docRef);
    //     console.log(`Deleted document with ID: ${docId}`);
    //   }
    //   console.log("All erroneous documents deleted successfully.");
    // } catch (error) {
    //   console.error("Error in deleting documents: ", error);
    // }



export const phoneLogin = async (
  phoneNumber,
  userID,
  name,
  dispatch,
) => {
  try {
    dispatch(
      login({
        phone: phoneNumber,
        id: userID
      })
    );
    await setDoc(
      doc(db, "users", userID),
      {
        uid: userID,
        bio: "Building things 🚀",
        phone: phoneNumber,
        joinedCircle: [],
        name: name,
        socials:[],
        coverPic:"https://firebasestorage.googleapis.com/v0/b/jooby-b9791.appspot.com/o/images%2Fundefined%2Fuser_default.png?alt=media&token=7df8b561-4914-4742-9bd6-0bccbf0e27af",
        followers: [],
        following: [],
      });

    toast.success(`Welcome, ${userID}!`, {
    });
  } catch (error) {
    toast.error("Couldn't log you in!");
    console.log(error.message);
  }
};



export const getUser = async (user, setUserData) => {
  console.log("Get user Called with: " + user)
  try {
    const userDoc = doc(usersCollection, user.uid);
    const docSnap = await getDoc(userDoc);
    if (docSnap.exists()) {
      console.log("Settting User Data")
      setUserData(docSnap.data());
      return true;
    } else {
      console.log("Could not retrieve user data");
      return false;

    }
  } catch (error) {
    console.log(error.message);
  }
};

export const profileUpdate = async (
  userData,
  currentUser,
) => {try{ await setDoc(
  doc(db, "users", currentUser?.uid),
  {
    bio: userData?.bio ?? "",
        dedication: userData?.dedication ?? "",
        fullName: userData?.fullName ?? "",
        instagram: userData?.instagram ?? "",
        interests: userData?.interests ?? "",
        linkedIn: userData?.linkedIn ?? "",
        major: userData?.major ?? "",
        networkingGoal: userData?.networkingGoal ?? "",
        profilePic: userData?.profilePic ?? "",
        role: userData?.role ?? "",
        phone: userData?.phone ?? ""

  
  },
  { merge: true }
);
toast.success("Profile updated succesfully!")
console.log("toasted!")

}

catch(e){
  toast.error("Couldn't update")
  console.log(e)
}
}
 

export const addSocials = async (
  currentUser,
  link
) => {
  try{await setDoc(
    doc(db, "users", currentUser?.uid),
    {
      socials: arrayUnion(link)
    },
    { merge: true }
  );
  toast.success("Post sent." + link);

}
  catch(e){
    toast.error("failed"+ link)
  }
};

export const experienceAdd = async (
  currentUser,
  userData,
  userInfo,
  dispatch
) => {
  await setDoc(
    doc(db, "users", currentUser?.uid),
    {
      experience: arrayUnion({"jobTitle":userInfo?.jobTitle, 
      "company":userInfo?.company, "workDates":userInfo?.workDates, 
      "description":userInfo?.description, "companyLogo": userInfo?.companyLogo})
    },
    { merge: true }
  );
  await setDoc(
    doc(db, "users", currentUser?.uid),
    {
      allExp: arrayUnion({"jobTitle":userInfo?.jobTitle, 
      "company":userInfo?.company,
      "type": "Experience"
    })
    },
    { merge: true }
  );
  await updateProfile(currentUser, {
    photoURL: userData.avatar,
  });

  dispatch(updateDP(userData.avatar));
  console.log("Experience Added!")

};

export const educationAdd = async (
  currentUser,
  userData,
  userInfo,
  dispatch
) => {
  await setDoc(
    doc(db, "users", currentUser?.uid),
    {
      education: arrayUnion({"jobTitle":userInfo?.jobTitle, 
      "company":userInfo?.company, "workDates":userInfo?.workDates, 
      "description":userInfo?.description, "companyLogo": userInfo?.companyLogo})
    },
    { merge: true }
  );
  await setDoc(
    doc(db, "users", currentUser?.uid),
    {
      allExp: arrayUnion({"jobTitle":userInfo?.jobTitle, 
      "company":userInfo?.company,
      "type": "Education"
    })
    },
    { merge: true }
  );
  await updateProfile(currentUser, {
    photoURL: userData.avatar,
  });
  dispatch(updateDP(userData.avatar));
  console.log("Education Added!")

};
export const uploadImage = async (user, file) => {
  try {
    const path = `images/${user?.uid}/${file?.name}`;
    const imageRef = ref(storage, path);
    const response = file && (await uploadBytesResumable(imageRef, file));
    const pathName = response?.ref;
    const url = await getDownloadURL(pathName);
    return url;
  } catch (error) {
    toast.error("Could not upload image!");
    console.log(error.message);
  }
};

export const createPost = async (user, post) => {
  try {
    const loader = toast.loading("Posting...");
    const postDoc = await addDoc(allPostsCollection, {
      author: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL,
      caption: post.caption,
      createdAt: new Date().toLocaleString(),
      imageURL: post.imageURL,
      company: post.company,
      likes: [],
      comments: [],
    });

    await setDoc(
      doc(allPostsCollection, postDoc.id),
      {
        postID: postDoc.id,
      },
      { merge: true }
    );
    toast.success("Post sent.", { id: loader });
  } catch (error) {
    toast.error("Post not sent. Try again!");
  }
};
export const createFeed = async (user, post) => {
  try {
    const userDoc = doc(usersCollection, user);
    const docSnap = await getDoc(userDoc);
    const userData = docSnap.data();
   

    const postDoc = await addDoc(allFeed, {
      author: userData.name,
      uid: userData.uid,
      photoURL: userData.coverPic,
      caption: post.caption,
      createdAt: new Date().toLocaleString(),
      eventPic: post?.eventPic,
      eventName: post.eventName,
      eventID: post.eventID,
      likes: [],
      comments: [],
    });

    await setDoc(
      doc(allFeed, postDoc.id),
      {
        postID: postDoc.id,
      },
      { merge: true }
    );
    toast.success("added")
  } catch (error) {
    toast.error("Not added to feed")
    console.log(error)
  }
};

export const editPost = async (post, updatePost) => {
  try {
    await setDoc(
      doc(allPostsCollection, post.postID),
      {
        caption: updatePost.caption,
        imageURL: updatePost.imageURL,
      },
      { merge: true }
    );
  } catch (error) {
    toast.error("Couldn't update post. Try again!");
  }
};

export const deletePost = async (post) => {
  try {
    await deleteDoc(doc(allPostsCollection, post.postID));
    toast.success("Post deleted");
  } catch (error) {
    toast.error("Couldn't delete post. Try again!");
  }
};

export const likePost = async (postID, user) => {
  try {
    await setDoc(
      doc(allPostsCollection, postID),
      {
        likes: arrayUnion({
          avatar: user.photoURL,
          displayName: user.displayName,
          userID: user.uid,
        }),
      },
      { merge: true }
    );
  } catch (error) {
    toast.error("Couldn't like post. Try again!");
  }
};

export const dislikePost = async (postID, user) => {
  try {
    await setDoc(
      doc(allPostsCollection, postID),
      {
        likes: arrayRemove({
          avatar: user.photoURL,
          displayName: user.displayName,
          userID: user.uid,
        }),
      },
      { merge: true }
    );
  } catch (error) {
    toast.error("Couldn't dislike post. Try again!");
  }
};

export const postComment = async ({ postID }, comment, user, currentCircle) => {
  try {
    console.log(postID);
    await setDoc(
      doc(allPostsCollection, postID),
      {
        comments: arrayUnion({
          avatar: user.coverPic,
          displayName: user.name,
          userID: user.uid,
          comment: comment,
          date: date,
        }),
      },
      { merge: true }
    );
    const feedData = {caption: `${comment} to ${currentCircle?.circleName}!`, eventName: currentCircle.circleName, eventID:currentCircle.circleID, eventPic: currentCircle.pic}
    createFeed(user.uid, feedData);
    
  } catch (error) {
    // toast.error("Couldn't post the comment. Try again!");
    console.log(error);
  }
};
export const postCommentOpportunities = async ({ postID }, comment, user) => {
  try {
    console.log(postID);
    await setDoc(
      doc(allCircles, postID),
      {
        comments: arrayUnion({
          avatar: user.photoURL,
          displayName: user.displayName,
          userID: user.uid,
          comment: comment,
          date: date,
        }),
      },
      { merge: true }
    );
  } catch (error) {
    toast.error("Couldn't post the comment. Try again!");
    console.log(error);
  }
};

export const deleteComment = async (post, comment) => {
  try {
    await setDoc(
      doc(allPostsCollection, post.postID),
      {
        comments: arrayRemove(comment),
      },
      { merge: true }
    );
  } catch (error) {
    toast.error("Couldn't delete the comment. Try again!");
  }
};

export const followUser = async (currentUser, userToFollow) => {
  try {
    await setDoc(
      doc(collection(db, "users"), currentUser?.uid),
      {
        following: arrayUnion(userToFollow.uid),
      },
      { merge: true }
    );
    await setDoc(
      doc(collection(db, "users"), userToFollow.uid),
      {
        followers: arrayUnion(currentUser?.uid),
      },
      { merge: true }
    );
    toast.success(`You have reached out to ${userToFollow.name}`);
  } catch (error) {
    console.log(error)
    toast.error(`Couldn't reach out to ${userToFollow.name}. Try again!`);
  }
};

export const unfollowUser = async (currentUser, userToUnfollow) => {
  try {
    await setDoc(
      doc(collection(db, "users"), currentUser?.uid),
      {
        following: arrayRemove(userToUnfollow.uid),
      },
      { merge: true }
    );

    await setDoc(
      doc(collection(db, "users"), userToUnfollow.uid),
      {
        followers: arrayRemove(currentUser?.uid),
      },
      { merge: true }
    );
    toast.success(`Unfollowed ${userToUnfollow.name}`);
  } catch (error) {
    console.log(error)
    toast.error(`Couldn't unfollow ${userToUnfollow.name}. Try again!`);
  }
};

export const archivePost = async (post, user) => {
  try {
    await setDoc(
      doc(collection(db, "users"), user?.uid),
      {
        archives: arrayUnion(post),
      },
      { merge: true }
    );
    await deleteDoc(doc(allPostsCollection, post.postID));
    toast.success("Post archived");
  } catch (error) {
    toast.error("Couldn't archive post. Try again!");
  }
};

export const unarchivePost = async (post, user) => {
  try {
    await setDoc(
      doc(collection(db, "users"), user?.uid),
      {
        archives: arrayRemove(post),
      },
      { merge: true }
    );
    await setDoc(doc(allPostsCollection, post.postID), post, { merge: true });
    toast.success("Post unarchived");
  } catch (error) {
    toast.error("Couldn't unarchive post. Try again!");
  }
};

export const bookmarkPost = async (post, user) => {
  try {
    await setDoc(
      doc(collection(db, "users"), user?.uid),
      {
        bookmarks: arrayUnion(post),
      },
      { merge: true }
    );
    toast.success("Post bookmarked");
  } catch (error) {
    toast.error("Couldn't bookmark post. Try again!");
  }
};

export const undoBookmarkPost = async (post, user) => {
  try {
    await setDoc(
      doc(collection(db, "users"), user?.uid),
      {
        bookmarks: arrayRemove(post),
      },
      { merge: true }
    );
    toast.success("Removed from bookmarks");
  } catch (error) {
    toast.error("Couldn't remove from bookmarks. Try again!");
  }
};
//         Firebase Calls for Circles Section
 
//getCircle --> Obtains Circle Data
/* Takes ID and creates entire circle */
export const getCircle = async (circle, setCircleData) => {
  try {
    console.log(circle)
    const circleDoc = doc(collection(db, "allCircles"), circle);
 console.log("CircleDoc:" + circleDoc)
    const docSnap = await getDoc(circleDoc);
    if (docSnap.exists()) {
      setCircleData(docSnap.data());
    } else {
      console.log("Could not retrieve circle data");
    }
  } catch (error) {
    console.log(error);}}

 export const createCircle = async (currentUser, circleData) => {
  try {
    //UI
    const loader = toast.loading("Creating Circle...");
  
    // Create Blank Circles
    const circleDoc = await addDoc(collection(db, "allCircles"), {
      name: circleData?.name,  // Updated
      competitionTrack: circleData?.competitionTrack,  // Updated
      roles: circleData?.roles,  // Updated
      description: circleData?.description,  // Updated
      market: circleData?.market,  // Updated
      creator: currentUser?.uid,
      memberCount: [],
      logo: circleData?.logo,
      // creatorName: currentUser?.name,
      // creatorPic: currentUser?.profilePic,
      // hostPhone: currentUser?.phone,
      // pic: circleData?.coverPic,
      createdAt: new Date().toLocaleString(),
      functionality: circleData?.functionality,
      views: 0,
      organization: "NVC"
    });
  
    await setDoc (
      doc(collection(db, "allCircles"), circleDoc.id), {
        startUpID: circleDoc.id,
        memberCount: arrayUnion({"uid": currentUser.uid })
      },
      { merge: true }
    );

  

    //UI
    toast.success("Startup created.", { id: loader });
    console.log("Startup created at " + circleDoc.id)
    return circleDoc.id;


  } catch (error) {
    toast.error("Startup not created. Try again!");
    console.log(error);
  }
};

export const updateCircle = async (circleId, updatedCircleData) => {
  try {
    // UI feedback for starting the update process
    const loader = toast.loading("Updating Circle...");

    // Reference to the specific circle document
    const circleDocRef = doc(db, "allCircles", circleId);

    // Update the circle document with new data
    await updateDoc(circleDocRef, {
      name: updatedCircleData?.name,
      competitionTrack: updatedCircleData?.competitionTrack,
      roles: updatedCircleData?.roles,
      description: updatedCircleData?.description,
      market: updatedCircleData?.market,
      logo: updatedCircleData?.logo,
      functionality: updatedCircleData?.functionality,
      // Add any other fields you need to update
    });

    // UI feedback for successful update
    toast.success("Circle updated successfully.", { id: loader });
    console.log("Circle updated at " + circleId);
  } catch (error) {
    // UI feedback and console log for errors
    toast.error("Circle update failed. Try again!");
    console.error("Error updating circle: ", error);
  }
};
  
//  export const editCircle = async (
//   userInfo,
//   circleData,
//   startTime,
//   endTime,
//   selectedDay,
//   dispatch
//  ) => {
//   await setDoc (
//     doc(db, "allCircles", circleData?.circleID),
//     {
//         pic: userInfo?.pic
//   ? userInfo?.pic
//   : circleData.pic ?? "",
//   circleName: userInfo?.circleName
//   ? userInfo?.circleName
//   : circleData.circleName ?? "",
//   circleCosts: userInfo?.circleCosts
//   ? userInfo?.circleCosts
//   : circleData.circleCosts ?? "",
//   circleDescription: userInfo?.circleDescription
//   ? userInfo?.circleDescription
//   : circleData.circleDescription ?? "",
//   start: startTime
//   ? startTime.toLocaleString()
//   : circleData.start ?? "",
//   end: endTime
//   ? endTime.toLocaleString()
//   : circleData.end ?? "",
//   date: selectedDay
//   ? new Date(`${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`)
//   : circleData.date ?? "",
  
//  },
  
//     { merge: true }
//   )
//  // dispatch(updateDP(userData.avatar));
//   };
//   export const textBlast = async (currentCircle) => {
//     const options = { month: 'long', day: 'numeric'};
//     try{
//       for(const user of currentCircle?.memberCount){
//         if ('number' in user){
//           await setDoc(
//             doc(collection(db, "messages")),
//             {
//               to: `${user?.number}`,
//               body: `I want to check if you are still going to ${currentCircle?.circleName} happening on ${new Date(currentCircle?.date?.seconds*1000).toLocaleString("en-US", options)}. If you change your mind, please change your RSVP here: https://disko.rsvp/circle/`+ currentCircle?.circleID,
//               // body:  "https://disko.vercel.app/circle/"+ currentCircle?.circleID
//             },
//             { merge: true });
//         }
//       }
//       toast.success("Message sent to Attendees")
//     }
//     catch(error){
//       toast.error("Message not sent")
//       console.log(error)}
//   }

  
export const joinCircle = async (currentUser, currentCircle, response) => {
  try {
    let updatedMemberCount;

    if (currentCircle.memberCount.some(user => user.uid === currentUser.uid)) {
      // User already in memberCount, update their response
      updatedMemberCount = currentCircle.memberCount.map(member => 
        member.uid === currentUser.uid ? { ...member, response: response } : member
      );
    } else {
      // Add new user to memberCount with their response
      updatedMemberCount = [
        ...currentCircle.memberCount,
        { uid: currentUser.uid, response: response }
      ];
    }

    // Update the currentCircle with the new memberCount
    await setDoc(
      doc(collection(db, "allCircles"), currentCircle.startUpID),
      { memberCount: updatedMemberCount },
      { merge: true }
    );

    console.log(`User ${currentUser.uid} response updated to: ${response}`);
  } catch (error) {
    console.error("Error joining circle:", error);
    // Handle the error
  }
};


  export const viewCount = async(circleID) => {
    try{
        await updateDoc(
        doc(collection(db, "allCircles"), circleID),
        {
          views: increment(1)
        },
        { merge: true })
        console.log("count initiated")
      
     
    } catch (error){
      console.log(error)
    }
    
  }

  export const archiveCircle = async(circleID) => {
    try{
        await updateDoc(
        doc(collection(db, "allCircles"), circleID),
        {
          archive: true
        },
        { merge: true })
        toast.success("Event Archived!")
    } 
    catch (error){
      console.log(error)
    }
    
  }

  export const userViewCount = async(userID) => {
    try{
        await updateDoc(
        doc(collection(db, "users"), userID),
        {
          views: increment(1)
        },
        { merge: true })
      
     
    } catch (error){
      console.log(error)
    }
    
  }
  export const logReactionEyes = async(circleID) => {
    try{
        await updateDoc(
        doc(collection(db, "allCircles"), circleID),
        {
          eyesEmoji: increment(1)
        },
        { merge: true })
      
    } catch (error){
      console.log(error)
    }
    
  }
  export const logReactionAlarm = async(circleID) => {
    try{
        await updateDoc(
        doc(collection(db, "allCircles"), circleID),
        {
          alarmEmoji: increment(1)
        },
        { merge: true })
      
    } catch (error){
      console.log(error)
    }
  }
  export const logReactionFire = async(circleID) => {
    try{
        await updateDoc(
        doc(collection(db, "allCircles"), circleID),
        {
          fireEmoji: increment(1)
        },
        { merge: true })
      
    } catch (error){
      console.log(error)
    }
    
  }


export const createOpportunities = async (user, Circle, post) => {
  // try {
  //   const loader = toast.loading("Posting...");
  //   await setDoc(
  //     doc(collection(db, "allCircles"), Circle),
  //     {
  //       Opportunities: arrayUnion({
  //         author: user.displayName,
  //         uid: user.uid,
  //         photoURL: user.photoURL,
  //         caption: post.caption,
  //         createdAt: new Date().toLocaleString(),
  //         imageURL: post.imageURL,
  //         company: post.company,
  //         likes: [],
  //         comments: [],
  //         circleID: Circle
  //       }),
  //     },
  //     { merge: true }
  //   );

  //   toast.success("Opportunities sent.", { id: loader });
  //   completeChallenge(user, Circle);
  // } catch (error) {
  //   toast.error("Opportunities not sent. Try again!");
  //   console.log(error)
  // }
  try {
    // const loader = toast.loading("Posting...");
    const postDoc = await addDoc(allPostsCollection, {
      author: user.name,
      uid: user.uid,
      photoURL: user.coverPic,
      caption: post.caption,
      createdAt: new Date().toLocaleString(),
      imageURL: post.imageURL,
      company: post.company,
      likes: [],
      comments: [],
      circle: Circle,
      type: "Opportunities",

    });

    await setDoc(
      doc(allPostsCollection, postDoc.id),
      {
        postID: postDoc.id,
      },
      { merge: true }
    );
    // toast.success("Post sent.", { id: loader });
  } catch (error) {
    // toast.error("Post not sent. Try again!");
    console.log("chat section failed")
  }

};

//Opportunities



// Create Q & A
// use above as template
export const createQuestion = async (user, Circle, post) => {
  try {
    // const loader = toast.loading("Posting...");
    const postDoc = await addDoc(allPostsCollection, {
      author: user.name,
      uid: user.uid,
      photoURL: user.coverPic,
      caption: post.caption,
      createdAt: new Date().toLocaleString(),
      imageURL: post.imageURL,
      company: post.company,
      likes: [],
      comments: [],
      circle: Circle,
      type: "QA",

    });

    await setDoc(
      doc(allPostsCollection, postDoc.id),
      {
        postID: postDoc.id,
      },
      { merge: true }
    );
    // toast.success("Post sent.", { id: loader });
  } catch (error) {
    console.log(error)
    // toast.error("Post not sent. Try again!");
  }
};
export const updateDiamonds = async(circleID, diamond, engagementScore) => {
  try {
    await setDoc(
      doc(collection(db, "allCircles"), circleID),
      {
        diamondCount: diamond,
        challenges: [],
        engagement: 0,
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error)
  }
  
  
}