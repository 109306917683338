export const columnsDataUsersOverview = [
  {
    Header: 'USER NAME',
    accessor: 'name',
  },
 
  {
    Header: 'LINKEDIN',
    accessor: 'linkedIn',
  },
  
  {
    Header: 'Major',
    accessor: 'major',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
 
];
