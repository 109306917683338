import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, profileUpdate, uploadImage } from "../../../../../../firebase/firebase-calls";
import { auth } from "../../../../../../firebase/firebase";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast } from "react-hot-toast";
import { MdOutlineCloudUpload } from "react-icons/md";


import Card from 'components/card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import QRCode from 'qrcode.react';

const Information = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [profilePic, setProfilePic] = useState('');

  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const loader = toast.loading("Uploading image...");
    const path = `profile_images/${currentUser?.uid}/${file.name}`;
    const uploadedImageUrl = await uploadImage(path, file);
    toast.success("Image uploaded successfully!", { id: loader });
  
    setProfilePic(uploadedImageUrl);
    setUserData({ ...userData, profilePic: uploadedImageUrl });
  };
  
  const authToken = localStorage.getItem('authToken');
  if (!authToken) { navigate('/auth/sign-in/centered'); }
  
  // console.log("Auth Token in User Profile: " + authToken);
  const currentUser = auth?.currentUser;

  const calculateCompleteness = (userData) => {
    const fields = ['fullName', 'instagram', 'linkedIn', 'major', 'bio', 'dedication', 'networkingGoal', 'interests', 'phone', "role"];
    const filledFields = fields.filter(field => userData[field]);
    return (filledFields.length / fields.length) * 100;
  };

  const completeness = calculateCompleteness(userData);


  useEffect(
    () => {
      if (currentUser) {
        // Check if userData is an empty object
        if (Object.keys(userData).length === 0) {
          getUser(currentUser, setUserData)
            .then(() => {
              // Update loading state after fetching user data
              setLoading(false);
            })
            .catch(error => {
              console.error("Error fetching user data:", error);
            });
        }
    }
  },
    // eslint-disable-next-line
    [currentUser]
  );

  // const handleInputChange = (e) => {
  //   const { id, value } = e.target;
  //   setUserData({
  //     ...userData,
  //     [id]: value
  //   });
  // };
  
  
    

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Function to format URL
   
   


    setUserData({
      ...userData,
      [id]: value
    });
};

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    //Function URL

  // Function to format URL
  const formatURL = (url, platform) => {
    const prefix = platform === 'instagram' ? 'https://www.instagram.com/' : 'https://www.linkedin.com/in/';
    const regex = platform === 'instagram' ? /^https?:\/\/(www\.)?instagram\.com\// : /^https?:\/\/(www\.)?linkedin\.com\/in\//;

    if (!regex.test(url)) {
      // Remove any existing protocol and domain from the url
      const urlParts = url.split('/');
      const username = urlParts[urlParts.length - 1];
      return `${prefix}${username}`;
    }
    return url;
  };

  // Create a copy of userData

  // Only include profilePic in updatedUserData if it has been updated
  const updatedUserData = profilePic 
      ? { ...userData, profilePic } 
      : { ...userData };

  // Format Instagram and LinkedIn URLs
  if (updatedUserData.instagram) {
    updatedUserData.instagram = formatURL(updatedUserData.instagram, 'instagram');
  }
  if (updatedUserData.linkedIn) {
    updatedUserData.linkedIn = formatURL(updatedUserData.linkedIn, 'linkedIn');
  }
  await profileUpdate(updatedUserData, currentUser);
    await profileUpdate( updatedUserData, currentUser);  // Assuming updateUser updates the user data
    navigate("/admin/nvc")
  };
  const majors = [
    "Biomedical Engineering, B.S.",
    "Biomedical Engineering: Premedical, B.S.",
    "Business Administration, B.A.",
    "Business Administration, MBA",
    "Business Economics, B.A.",
    "Business Information Management, B.S.",
    "Chemical Engineering, B.S.",
    "Chemistry, B.S.",
    "Chicano/Latino Studies, B.A.",
    "Chinese Studies, B.A.",
    "Civil Engineering, B.S.",
    "Classics, B.A.",
    "Cognitive Sciences, B.S.",
    "Comparative Literature, B.A.",
    "Computer Engineering, B.S.",
    "Computer Science, B.S.",
    "Computer Science and Engineering, B.S.",
    "Criminology, Law and Society, B.A.",
    "Dance, B.A.",
    "Data Science, B.S.",
    "Developmental and Cell Biology, B.S.",
    "Drama, B.A.",
    "Earth System Science, B.S.",
    "East Asian Cultures, B.A.",
    "Ecology and Evolutionary Biology, B.S.",
    "Economics, B.A.",
    "Education Sciences, B.A.",
    "Electrical Engineering, B.S.",
    "Engineering, B.S.",
    "English, B.A.",
    "Environmental Engineering, B.S.",
    "Environmental Science and Policy, B.A.",
    "European Studies, B.A.",
    "Exercise Sciences, B.S.",
    "Film and Media Studies, B.A.",
    "French, B.A.",
    "Game Design and Interactive Media, B.S.",
    "Gender and Sexuality Studies, B.A.",
    "Genetics, B.S.",
    "German Studies, B.A.",
    "Global Cultures, B.A.",
    "Global Middle East Studies, B.A.",
    "History, B.A.",
    "Human Biology, B.S.",
    "Informatics, B.S.",
    "International Studies, B.A.",
    "Japanese Language and Literature, B.A.",
    "Korean Literature and Culture, B.A.",
    "Language Science, B.A.",
    "Literary Journalism, B.A.",
    "Master of Business Administration, MBA",
    "Master of Innovation and Entrepreneurship, MIE",
    "Materials Science and Engineering, B.S.",
    "Mathematics, B.S.",
    "Mechanical Engineering, B.S.",
    "Microbiology and Immunology, B.S.",
    "Music, B.A.",
    "Neurobiology, B.S.",
    "Nursing Science, B.S.",
    "Pharmaceutical Sciences, B.S.",
    "Philosophy, B.A.",
    "Physics, B.S.",
    "Political Science, B.A.",
    "Psychology, B.A., B.S.",
    "Psychological Science, B.A.",
    "Public Health Sciences, B.S. / Public Health Policy, B.A.",
    "Quantitative Economics, B.A.",
    "Religious Studies, B.A.",
    "Social Ecology, B.A.",
    "Social Policy and Public Service, B.A.",
    "Sociology, B.A.",
    "Software Engineering, B.S.",
    "Spanish, B.A.",
    "Urban Studies, B.A.",
    "Other/Not Listed/Undeclared"
  ];
  

  return (
    (!loading && <Card extra={'w-full mt-10 px-6 py-6 '}>
    {/* Header */}
    <div className="w-full px-[8px] ">
    <div className="flex items-center justify-between">
  <div>
    <QRCode value={`https://disko-ai.vercel.app/admin/main/profile/overview/${currentUser.uid}`} />
  </div>
  <div className="w-full flex justify-center my-4">
          <div style={{ width: '100px', height: '100px' }}>
            <CircularProgressbar
              value={completeness}
              text={`${completeness.toFixed(0)}%`}
              styles={{
                path: {
                  // Path color based on completeness
                  stroke: completeness === 100 ? '#4CAF50' : '#DAA520', // Darker shade of yellow
                },
                text: {
                  // Customize text style
                  fill: completeness === 100 ? '#4CAF50' : '#DAA520', // Darker shade of yellow
                  fontSize: '16px',
                  fontWeight: 'bold',
                },
              }}
            />
          </div>
        </div>
  <button 
    onClick={handleFormSubmit}
    className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
  >
    Save Changes 
  </button>
</div>
      <h4 className=" text-xl font-bold text-navy-700 dark:text-white">
        Account Settings 
      </h4>
      <p className="mt-1 text-base text-gray-600">
      Feel free to edit later! Change your account information here. Remember to save your changes! The progress bar will reflect your completion. 📈
      </p>
    </div>
    
  
    {/* inputs */}

    <div className="mt-[37px] grid grid-cols-1 gap-3 md:grid-cols-2">
    <div className="relative mt-4 w-full flex justify-center">
  <label htmlFor="profilePic" className="relative h-[150px] w-[150px] cursor-pointer rounded-full">
    {userData?.profilePic ? (
      <img src={userData?.profilePic} alt="Profile" className="h-full w-full object-cover rounded-full" />
    ) : (
      <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-200">
        <MdOutlineCloudUpload size={50} />
      </div>
    )}
    <div className="absolute bottom-0 flex w-full items-center justify-center bg-gray-300 bg-opacity-70 py-1 rounded-b-full">
      <span className="text-brand-500 font-bold">Change Picture</span>
    </div>
    <input
      type="file"
      id="profilePic"
      accept="image/*"
      className="hidden"
      onChange={handleProfileImageUpload}
    />
  </label>
</div>
    <InputField
        extra="mb-3"
        label="Full Name "
        placeholder="John Doe"
        id="fullName"
        type="text"
        value={userData?.fullName || ''}
        onChange={handleInputChange}
      />
       <InputField
        extra="mb-3"
        label="Phone  (We won't solicit. Used only for NVC)"
        placeholder="(408) 999-999"
        id="phone"
        type="text"
        value={userData?.phone || ''}
        onChange={handleInputChange}
      />
      <InputField
        extra="mb-3"
        label="Instagram "
        placeholder="john.doe"
        id="instagram"
        type="text"
        value={userData?.instagram || ''}
        onChange={handleInputChange}
      />
      <InputField
        extra="mb-3"
        label="LinkedIn Username "
        placeholder="johndoe"
        id="linkedIn"
        type="text"
        value={userData?.linkedIn || ''}
        onChange={handleInputChange}
      />
      <div className="mb-3">
        <label htmlFor="major" className="block text-sm font-bold">Major  (Type to search your major)</label>
        <input 
          list="majors"
          id="major"
          name="major"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={userData?.major || ''}
          onChange={handleInputChange}
          placeholder="Type to search..."
        />
        <datalist id="majors">
          {majors.map((major, index) => (
            <option key={index} value={major} />
          ))}
        </datalist>
      </div>
      {/* User Role Selection */}
      <div className="mb-10">
        <label htmlFor="userRole" className="block text-sm font-bold">Your Role (Select your current status)</label>
        <select
          id="role"
          name="role"
          value={userData?.role || ''}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="">Select your role</option>
          <option value="UCI Undergraduate Student">I'm a UCI Undergraduate Student</option>
          <option value="UCI Graduate Student">I'm a UCI Graduate Student</option>
          <option value="UCI Post Doc">I'm a UCI Post Doc</option>
          <option value="UCI Faculty">I'm UCI Faculty</option>
          <option value="UCI Staff">I'm UCI Staff</option>
          <option value="Student at Another University">I'm a Student at Another University</option>
          <option value="Community Member">I'm a Community Member (Non-UCI, Non-Student)</option>
        </select>
      </div>
     
  </div>
    {/* full width inputs */}
    {/* ...other InputFields */}
    <TextField
      extra="mb-4"
      label="Bio 📝 (Tell us more about your background)"
      placeholder="Tell us more about your background"
      id="bio"
      cols="30"
      rows="5"
      value={userData?.bio || ""}
      onChange={handleInputChange}
    />
    <TextField
      extra="mb-4"
      label="Goals 🎯 (What are you striving for currently?)"
      placeholder="What do you hope to achieve out of NVC and career?"
      id="dedication"
      cols="30"
      rows="5"
      value={userData?.dedication || ''}
      onChange={handleInputChange}
    />
    <TextField
      extra="mb-4"
      label="Networking Preference 🤝 (Who do you want to meet?)"
      placeholder="What type of person are you looking & hoping to work with?"
      id="networkingGoal"
      cols="30"
      rows="5"
      value={userData?.networkingGoal || ''}
      onChange={handleInputChange}
    />
    {/* <InputField
      extra="mb-3"
      label="Profile Picture URL"
      placeholder="https://example.com/your-photo.jpg"
      id="profilePic"
      type="text"
      value={userData?.profilePic || ''}
      onChange={handleInputChange}
    /> */}
    <InputField
      extra="mb-3"
      label="Interests and Hobbies 🏞️ (What is fun or piques your interest?)"
      placeholder="Tell us more about your hobbies and what you do for fun!"
      id="interests"
      value={userData?.interests || ""}
      onChange={handleInputChange}
    />
    <div className="flex w-full justify-end">
      <button 
        onClick={handleFormSubmit}
        className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      >
        Save Changes (In case you missed me)💾
      </button>
    </div>
  </Card>)
    
  );
};

export default Information;
