import Card from "components/card";
import MasterCard from "components/card/MasterCard";
import Transaction from "components/dataDisplay/Transaction";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import TimelineItem from "components/dataDisplay/TimelineItem";
//assets
import {
  MdOutlineShoppingBasket,
  MdOutlineDirectionsBus,
  MdOutlineSubscriptions,
  MdLocalBar,
  MdOutlineWeekend,
  MdCached,
  MdAdd,
  MdAttachMoney,
  MdMoreHoriz,
} from "react-icons/md";
import { RiNetflixFill } from "react-icons/ri";

function YourCard(props) {
  return (
    <Card extra={"w-full h-fit 3xl:h-full px-3 pt-3 2xl:pb-5"}>
      {/* Header */}
      <div className="mt-[30px]">
  {/* Workshops from UCI New Venture Competition */}
  
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 2: Form Your Winning Team & Rules + Mixer"
        day="16"
        weekday="Jan"
        hours="TBD"
        current="current"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 3: From Idea to Business + Mixer"
        day="23"
        weekday="Jan"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 4: How to Research Your Market + Mixer"
        day="30"
        weekday="Jan"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 5: How to Write a Winning Concept Paper + Mixer"
        day="06"
        weekday="Feb"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 6: Designing Your Concept Paper"
        day="20"
        weekday="Feb"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 7: How to Make Money + Mixer"
        day="09"
        weekday="Apr"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 8: How to Go to Market + Mixer"
        day="16"
        weekday="Apr"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 9: How to Build a Winning Pitch Deck + Mixer"
        day="23"
        weekday="Apr"
        hours="TBD"
    />
  </a>
  <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
    <TimelineItem
        mb="mb-[16px]"
        title="Workshop 10: How to Pitch & Deal With Q&A + Mixer"
        day="30"
        weekday="Apr"
        hours="TBD"
    />
  </a>
  </div>
    </Card>
  );
}

export default YourCard;
