import NftBanner2 from "assets/img/nfts/NftBanner2.png";
import NftProfile from "assets/img/nfts/NftProfile.png";
import { MdVerified } from "react-icons/md";
import { FaEthereum, FaDollarSign } from "react-icons/fa";
import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";


import background from "assets/img/nvc/nvc-header-24_r1a.jpg"
import logo from "assets/img/nvc/uciMerage2.png"

const Banner = () => {
  return (
    <div className="flex h-full w-full flex-col items-center rounded-[20px]">
      <div
        style={{ backgroundImage: `url(${background})` }}
        className="relative flex h-[150px] w-full items-center justify-center rounded-[20px] bg-cover md:h-[120px] lg:h-[170px] 3xl:h-[220px]"
      >
        <div className="absolute -bottom-20 flex h-[180px] w-[180px] items-center justify-center rounded-full border-[10px] border-white bg-navy-700 dark:!border-navy-900">
          <img className="h-full w-full rounded-full" src={logo} alt="" />
        </div>
      </div>
      {/* profile info */}
      <div className="mt-[85px] flex flex-col items-center">
        <p className="mt-[10px] flex w-full items-center justify-center  text-gray-600">

            <h1 className="ml-1 text-3xl font-bold text-[#000000] dark:text-white">
                Stella Zhang®
              </h1>
          </p>
        <p className="mt-[10px] flex w-full items-center justify-center text-lg font-medium text-gray-600">
          
          <p className="ml-1 text-base font-bold text-[#3965FF] dark:text-white">
            New Venture Competition
          </p>
          <p className="ml-1 text-[#3965FF]">
            <MdVerified />
          </p>
        </p>
        <p className="mt-[10px] flex w-full items-center justify-center text-lg font-medium text-gray-600">
          Find teams and teammates{" "}
          <p className="ml-1 text-base font-bold text-[#3965FF] dark:text-white">
            below
          </p>
         
        </p>
      </div>
      {/* eth owned */}
      {/* <Card
        extra={
          "grid grid-cols-1 dark:shadow-none md:grid-cols-1 w-full md:w-[800px] h-full md:h-[160px] gap-2 py-4 mt-9"
        }
      >
       
        <div className="flex flex-col items-center justify-center border-gray-200 py-3 dark:border-white/10 md:py-0">
          <h3 className="flex items-center text-4xl font-bold text-navy-700 dark:text-white">
            <p className="text-3xl">
              <FaDollarSign />
            </p>
            100,000
          </h3>
          <p className="text-base text-gray-600">Prize</p>
        </div>

      </Card> */}
      <div className="flex h-full w-full items-center justify-center md:w-[760px] lg:w-[870px]">
        <p className="mt-10 w-[280px] text-center font-dm text-lg leading-8 text-navy-700 dark:text-white md:w-full md:text-lg">
        Calling All Creators, Inventors, and Aspiring Entrepreneurs!
        The UCI New Venture Competition offers you the opportunity to form a team, launch a startup and potentially fund a business idea 
        – all within seven months. The competition is open to all UCI students, staff members and researchers as well as community members. 
        Teams compete for $100,000 in cash prizes plus additional professional services!
        </p>
      </div>
      <Card extra={"w-full p-5"}>
      {/* Header */}
      <div>
  <h4 className="text-xl font-bold text-navy-700 dark:text-white">
    Event Dates
  </h4>
  <p className="text-base font-medium text-gray-600">
    Don't miss out on these big dates!
  </p>
  <div className="mt-4">
    <h5 className="text-lg font-bold text-navy-700 dark:text-white">
      Important Update: Workshop Rescheduled
    </h5>
    <p className="text-base font-medium text-gray-600">
      Hi everyone,
      <br /><br />
      Due to the expected heavy rain and flash flood warning tonight, we've made the call to reschedule the <strong>How to Write a Winning Concept Paper workshop</strong> originally planned for today, Feb 6th at 5:30PM.
      <br /><br />
      Don't worry! We've secured a new date: <strong>Monday, Feb 12th, from 5:30 PM - 7:30 PM</strong>, at the same location, MPAA100.
      <br /><br />
      Please update your calendars and RSVP again if you can still join us. If not, let us know ASAP so we can adjust accordingly.
      <br /><br />
      Stay safe and dry!
      <br /><br />
      Sincerely,
      <br />
      SZNVC Team
      <br /><br />
      P.S. We apologize for any inconvenience and appreciate your understanding.
    </p>
  </div>
</div>

      
     
 
      {/* Timeline items */}
      <div className="mt-[30px]">
   
    <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
        <TimelineItem
            mb="mb-[16px]"
            title="Concept Papers Due"
            day="10"
            weekday="Mar"
            hours="10:30 - 12:00"
            current="current"
        />
    </a>
    <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
        <TimelineItem
            mb="mb-[16px]"
            title="Board Room Pitch"
            day="10"
            weekday="May"
            hours="09:00 - 14:00"
        />
    </a>
    <a href="https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#comp" target="_blank" rel="noopener noreferrer">
        <TimelineItem
            title="Grand Finale & Awards"
            day="23"
            weekday="May"
            hours="20:00 - 22:30"
        />
    </a>
</div>

    </Card>
    </div>
  );
};

export default Banner;
