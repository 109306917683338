// import React from "react";
// import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";

// import { MdModeEditOutline } from "react-icons/md";
// import image1 from "assets/img/profile/image1.png";
// import image2 from "assets/img/profile/image2.png";
// import image3 from "assets/img/profile/image3.png";
// import Card from "components/card";
// import {getUser} from "../../../../../../firebase/firebase-calls"
// import {auth} from "../../../../../../firebase/firebase"
// import { Link } from "react-router-dom";


// const Project = () => {

//   const [userData, setUserData] = useState([])
//   const currentUser = auth?.currentUser

//   const { allCircles } = useSelector((State) => State.allCircles);

//   const filteredCircles = allCircles?.filter(
//     (circle) => circle.circleCreator === currentUser?.uid
//   );

//   useEffect(
//     () => {
//       if (userData.length <= 10) {
//         getUser(currentUser, setUserData);

//       }
//     },
//     // eslint-disable-next-line
//     [currentUser]
//   );

//   return (
//     <Card extra={"w-full p-4 h-full"}>
//       <div className="mb-8 w-full">
//         <p className="text-xl font-bold text-navy-700 dark:text-white">
//           Teams
//         </p>
//         <p className="mt-2 text-base text-gray-600">
//           Here you can find more details about your current/prev teams.
//         </p>
//       </div>
       

//       {filteredCircles?.length > 0 ? (
//                 filteredCircles?.map((circles) => (
//                   <Link className="button rounded-lg h-full w-full md:mb-4"
//                     to={`/admin/nfts/${circles?.startUpID}`}
//                   >
                // <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 dark:!bg-navy-700 dark:shadow-none">
                //   <div className="flex items-center">
                //     <div className="">
                //       <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
                //     </div>
                //     <div className="ml-4">
                //       <p className="text-base font-medium text-navy-700 dark:text-white">
                //         {circles?.name}
                //       </p>
                //       <p className="mt-2 text-sm text-gray-600">
                //       {circles?.competitionTrack}
                //         <a
                //           className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                //           href=" "
                //         >
                //           See startup details
                //         </a>
                //       </p>
                //     </div>
                //   </div>
                //   <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                //     <MdModeEditOutline />
                //   </div>
                // </div>
//                   </Link>
//           )))
//           : (
//           <div className="h-[30vh] w-full ml-6 overflow-none"><p className="text-sm md:text-md">The student is still working hard on their idea 💯. Join other projects at <a href="/home" className="underline text-blue-300">home screen</a></p></div>

//           )
//       }
//       {/* Project 1 Template */}
//       {/* 
//       <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 dark:!bg-navy-700 dark:shadow-none">
//         <div className="flex items-center">
//           <div className="">
//             <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
//           </div>
//           <div className="ml-4">
//             <p className="text-base font-medium text-navy-700 dark:text-white">
//               Technology behind the Blockchain
//             </p>
//             <p className="mt-2 text-sm text-gray-600">
//               Project #1 .
//               <a
//                 className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
//                 href=" "
//               >
//                 See product details
//               </a>
//             </p>
//           </div>
//         </div>
//         <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
//           <MdModeEditOutline />
//         </div>
//       </div>
//     */}

//     </Card>
//   );
// };

// export default Project;
import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/image1.png";
import Card from "components/card";
import { getUser } from "../../../../../../firebase/firebase-calls";
import { auth } from "../../../../../../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";

const Project = ({userID}) => {
  const [userData, setUserData] = useState([]);
  const currentUser = auth?.currentUser;

  const { allCircles } = useSelector((State) => State.allCircles);
  const filteredCircles = allCircles?.filter(
    (circle) => circle.creator === userID
  );

  useEffect(() => {
    if (userData.length <= 10) {
      getUser(currentUser, setUserData);
    }
  }, [currentUser]);

  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          Teams
        </p>
        <p className="mt-2 text-base text-gray-600">
          Here you can find more details about your current/prev teams.
        </p>
      </div>

      {filteredCircles?.length > 0 ? (
        filteredCircles?.map((circles) => (
          <Link className="button rounded-lg h-full w-full md:mb-4" to={`/admin/nfts/${circles?.startUpID}`}>
             <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 dark:!bg-navy-700 dark:shadow-none">
                  <div className="flex items-center">
                    <div className="">
                      <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
                    </div>
                    <div className="ml-4">
                      <p className="text-base font-medium text-navy-700 dark:text-white">
                        {circles?.name}
                      </p>
                      <p className="mt-2 text-sm text-gray-600">
                      {circles?.competitionTrack}
                        <a
                          className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                          href=" "
                        >
                          See startup details
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                    <MdModeEditOutline />
                  </div>
                </div>
          </Link>
        ))
      ) : (
        <div className="h-[20vh] w-full flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg shadow-lg">
        <p className="text-lg md:text-xl font-medium text-center mb-2">The student is still working hard on their idea 💯</p>
        <p className="text-sm md:text-base text-gray-600 mb-4">Want to explore more? Check out other projects on the</p>
        <Link to="/" className="underline text-blue-500 hover:text-blue-700 transition duration-300 text-md md:text-lg">
  Home Screen
</Link>
      </div>
      
      )}
    </Card>
  );
};

export default Project;
