import React from "react";
import { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";

import { getAuth, signOut } from "firebase/auth";
import {auth} from "../../firebase/firebase"
import {getUser} from "../../firebase/firebase-calls"


const Navbar = (props) => {

  const navigate = useNavigate();

  //get user

  const authToken = localStorage.getItem('authToken');

  const [userData, setUserData] = useState([])
  const currentUser = auth?.currentUser;



  useEffect(
    () => {
      if (userData.length == 0) {
        getUser(currentUser, setUserData);

      }
    },
    // eslint-disable-next-line
    [currentUser]
  );


  const handleSignout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log('signed out!')
      navigate("/auth/sign-in/centered")
      localStorage.removeItem("authToken");
      console.log("auth token" + localStorage.getItem("authToken"))
    }).catch((error) => {
      // An error happened.
      console.log(error.message)
    });
  }


  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );
  return (
    <nav
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
      className="fixed top-3 flex w-[calc(100vw_-_6%)] flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:top-[20px] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
    >
      <div className="ml-[6px]">
  <div className="h-6 w-[224px]">
  <Link
  to="/"
  className="text-2xl font-bold text-navy-700 hover:underline dark:text-white dark:hover:text-white"
>
  DISKO
</Link>
  </div>
</div>


      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
      
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-6 w-6 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-6 w-6 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        {!authToken ? (<button
  onClick={() => navigate('/auth/sign-in/centered')}
  className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
>
  Sign In
</button>) : (<Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={userData.profilePic}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                  👋 Hey, {userData.fullName}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <a
                  href="/admin/main/profile/settings"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                {/* <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a> */}
                <button
                  onClick={() => handleSignout()}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />)}
        
      </div>
    </nav>
  );
};

export default Navbar;
