import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector } from "react-redux";
import { getUser, createCircle, uploadImage,updateCircle } from "../../../../../../firebase/firebase-calls";
import { auth } from "../../../../../../firebase/firebase";
import SearchTableUsers from "../../../../main/users/overview/components/SearchTableUsersOverview"
import {columnsDataUsersOverview} from "../../../../main/users/overview/variables/columnsDataUsersOverview"


import DropZonefile from "./DropZonefile";
import DropZone from "./DropZone";
// Assets
import { MdOutlineCloudUpload } from "react-icons/md";
import { toast, Toaster } from "react-hot-toast";
//Progress
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { current } from "@reduxjs/toolkit";




const Information = () => {
  //Add Team members
  const transformUserData = (allUsers) => {
    return allUsers.map(user => ({
      name: [user.fullName, user.profilePic, user.userID],
      email: user.email,
      major: user.major || 'Undeclared', // fallback to 'Member' if major is not available
      // actions: 'Actions', // replace with actual actions if available
      linkedIn: user.linkedIn,
      networkingGoal: user.networkingGoal,
      interests: user.interests,
      dedication: user.dedication,
    }));
  };
  const { allUsers } = useSelector((state) => state.allUsers);
  
  const transformedData = transformUserData(allUsers);
  //end

  const [fileUpload, setFileUpload] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [startupData, setStartupData] = useState({
    name: '',
    competitionTrack: '',
    roles: '',
    description: '',
    market: '',
    logo: '',
    functionality: '',
  });

  const authToken = localStorage.getItem('authToken');
  if (!authToken) { navigate('/auth/sign-in/centered'); }
  
  const [loading, setLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { allCircles } = useSelector((state) => state.allCircles);
  const currentUser = auth?.currentUser;

  useEffect(() => {
    if (!isDataLoaded && allCircles.length > 0) {
      const filteredCircles = allCircles.filter(
        (circle) => circle.creator === currentUser?.uid
      );

      if (filteredCircles.length > 0) {
        const circleData = filteredCircles[0];
        setStartupData({
          name: circleData.name || '',
          competitionTrack: circleData.competitionTrack || '',
          roles: circleData.roles || '',
          description: circleData.description || '',
          market: circleData.market || '',
          logo: circleData.logo || '',
          functionality: circleData.functionality || ''
        });
        setIsDataLoaded(true); // Ensure this runs only once
      }
    }
  }, [allCircles, currentUser, isDataLoaded]);


  //progress bar
  const calculateStartupCompleteness = (startupData) => {
    const fields = ['name', 'competitionTrack', 'roles', 'description', 'market', 'logo', 'functionality'];
    const filledFields = fields.filter(field => startupData[field]);
    return (filledFields.length / fields.length) * 100;
  };
  const startupCompleteness = calculateStartupCompleteness(startupData);

  //handle User information
  const handleUserImage = async (e) => {
    console.log("handleUserBeingCalled")
    const file = e.target.files[0];
    const field = e.target.name;
    const loader = toast.loading("uploading image");
    const path = `images/${currentUser?.uid}/${file.name}`;
    const fieldURL = await uploadImage(path, file);
    toast.success("uploaded image", { id: loader });
    console.log("image uploaded successfully: " + fieldURL)
    setStartupData({ ...startupData, [field]: fieldURL });
    console.log(startupData)
    setFileUpload(true)
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setStartupData({
      ...startupData,
      [id]: value
    });
    console.log(startupData)
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
  
    setIsSubmitting(true);
  
    // Check if the team already exists
    const filteredCircles = allCircles.filter(
      (circle) => circle.creator === currentUser?.uid
    );
  
    if (filteredCircles.length > 0) {
      // If the team already exists, update it
      const circleId = filteredCircles[0].startUpID; // Assuming each circle has a unique ID
      await updateCircle(circleId, startupData); // Replace with your actual update function
    } else {
      // If the team does not exist, create a new one
      await createCircle(currentUser, startupData);
    }
  
    navigate("/");
  };


  //code for upload button
  const fileInputRef = React.useRef(null);
  const handleDropZoneClick = () => {
    console.log("opening upload screen")
    fileInputRef.current.click();
};
const input = document.getElementById('create_input');

  

  return (
    <Card extra={"w-full px-[20px] py-[22px] h-fit shadow-none"}>
      <Toaster></Toaster>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white mt-12 md:mt-0">
          Startup Information
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change your team information
        </p>
        <div className="w-full flex justify-center my-4">
        <div style={{ width: '100px', height: '100px' }}>
          <CircularProgressbar
            value={startupCompleteness}
            text={`${startupCompleteness.toFixed(0)}%`}
            styles={{
              path: {
                stroke: startupCompleteness === 100 ? '#4CAF50' : '#DAA520',
              },
              text: {
                fill: startupCompleteness === 100 ? '#4CAF50' : '#DAA520',
                fontSize: '16px',
                fontWeight: 'bold',
              },
            }}
          />
        </div>
      </div>
     
            
        {/* inputs */}
      </div>
      <Card extra={"w-full py-[26px] px-[0px] xl:h-[420px]"}>
            <p className="mt-1 text-xl font-bold text-navy-700 dark:text-white">
                Product Images
            </p>
           
          
            {/* <input
              type="file"
              id="pic"
              name="pic"
              accept="image/*, .gif"
              className="hidden"
              onChange={handleUserImage}
            />        */}
          
          <div className="flex w-full items-center justify-center rounded-[20px] bg-cover bg-clip-border p-3  dark:!bg-navy-800 dark:shadow-none">
          <label
            htmlFor="logo"
          >
            <input
              type="file"
              id="logo"
              name="logo"
              accept="image/*, .gif"
              className="hidden"
              onChange={handleUserImage}
            />          
            {startupData.logo ? (
              <div>
                <img
                  src={startupData.logo}
                  alt="Upload a photo of your product"
                  className="object-cover h-[300px] w-full cursor-pointer rounded-lg text-black"
                />
              </div>
            ) : (
              <div className="cursor-pointer flex h-[255px] xl:mt-12 flex-col items-center justify-center rounded-xl !border border-dashed border-gray-200 bg-gray-100 px-[5px] dark:!border-white/10 dark:!bg-navy-700 sm:!w-[400px] md:!w-[600px] lg:!w-[900px] xl:!w-full 2xl:!w-[420px] 3xl:!w-[500px]">
                <p className="text-[80px] text-navy-700 dark:text-white">
                <MdOutlineCloudUpload />
              </p>
                <span className="pl-2 font-bold text-brand-500 dark:text-brand-400">
                  Click to browse
                </span>
              <p className="text-lightFourth pt-2 text-sm font-medium">
                1600 x 1200 (4:3) recommended. PNG, JPG and GIF files are
                allowed
              </p>
              <h1 className="mt-2 text-brand-500 font-bold">Upload a file before filling out the fields below!</h1>
                
              </div>
            )}

          </label>
          </div>
        </Card>
     
      <div className="mt-9 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-3"
            label="Team Name"
            placeholder="eg. ScrubDaddy"
            id="name"
            type="text"
            value={startupData.name}

            onChange={handleInputChange}
            //why is there a disable fileupload

          />
          <InputField
            extra="mb-3"
            label="Competition Track"
            placeholder="eg. Tech startup"
            id="competitionTrack"
            type="text"
            value={startupData.competitionTrack}

            onChange={handleInputChange}
          />
          
          <InputField
            label="Target Market Industry"
            placeholder="eg. Finance, Biotech"
            id="market"
            type="text"
            value={startupData.market}

            onChange={handleInputChange}


          />
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-3"
            label="Team Roles Wanted"
            placeholder="eg. Software Engineer, Project Manager"
            id="roles"
            type="text"
            value={startupData.roles}
            onChange={handleInputChange}


          />
          {/* text area */}
          <TextField
            label="Description"
            placeholder="Short description about the product"
            id="description"
            cols="30"
            rows="6"
            value={startupData.description}
            onChange={handleInputChange}
            disabled={!fileUpload}


          />
          <TextField
            label="Functionality"
            placeholder="How does your product work?"
            id="functionality"
            cols="30"
            rows="6"
            value={startupData.functionality}
            onChange={handleInputChange}
            disabled={!fileUpload}


          />
        </div>
        <div className="flex w-full justify-end">
          <button 
            onClick={handleFormSubmit}
            className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            disabled={isSubmitting}
          >
            Save Changes
          </button>
        </div>
      </div>
      {/* <SearchTableUsers
          tableData={transformedData}
          columnsData={columnsDataUsersOverview}
      /> */}
    </Card>
     
  );
};

export default Information;
