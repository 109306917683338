import React from "react";
import { QrReader } from "react-qr-reader";
import { useRef, useState } from "react";
import  {getUser, updateEvent} from "../../../../firebase/firebase-calls";
import { useSelector } from "react-redux";



const CarInterface = () => {
   //QR CODE CHECKIN
   const [data, setData] = useState([]);
   const scannedUserIDsRef = useRef(new Set());
   const [isScanSuccessful, setIsScanSuccessful] = useState(false);
   const [isQrReaderActive, setIsQrReaderActive] = useState(false);

   const toggleQrReader = () => {
    setIsQrReaderActive(!isQrReaderActive);
  };

  //search for user
  const { allUsers } = useSelector((state) => state.allUsers);
  const [searchTerm, setSearchTerm] = useState("");
  const [flashingUserId, setFlashingUserId] = useState(null);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = allUsers.filter((user) =>
  user.fullName ? user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) : false
);

  const handleCheckInClick = async (userfullname, userId) => {
    // Check-in logic when a user is selected from the search results
    // Similar to the handleScan function logic
    console.log({fullName: userfullname, userID: userId})
    try {
      
        // Extract the needed information
     

        // Update event with check-in data
        await updateEvent({fullName: userfullname, userID: userId});
      }
      catch(error){
        console.log(error)
      }
      setFlashingUserId(userId);
      setTimeout(() => {
        setFlashingUserId(null);
      }, 1500); // Adjust the timing as needed
  };





  const handleScan = async (qrData) => {
    if (qrData) {
      const url = new URL(qrData);
      const pathSegments = url.pathname.split('/');
      const userId = pathSegments[pathSegments.length - 1];

      if (!scannedUserIDsRef.current.has(userId)) {
        setIsScanSuccessful(true);
        setTimeout(() => setIsScanSuccessful(false), 2000);
        scannedUserIDsRef.current.add(userId);

        try {
          const updateUserState = async (userData) => {
            setData(prevData => [...prevData, userData]);

            // Extract the needed information
            const checkInData = {
              fullName: userData?.fullName,
              userID: userData?.userID
            };

            // Update event with check-in data
            await updateEvent(checkInData);
          };

          // Fetch user data using getUser
          await getUser({ uid: userId }, updateUserState);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log('User already checked in:', userId);
      }
    }
  };
  return (
   
    <div className="mt-3 grid h-full grid-cols-1 gap-5 3xl:grid-cols-5">
      {/* Left Side */}
      <div className="text-center py-6 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg shadow-xl mx-4 my-4">
    <h1 className="text-4xl font-bold text-white">🚀 QR Check-In 🌟</h1>
    <p className="text-md mt-3 mr-3 ml-3 text-white">
      Quickly check-in students and mentors 🤝
    </p>
  </div>

      <div className="col-span-1 h-full w-full rounded-[20px] lg:grid-cols-11 3xl:col-span-3">
        <div className="grid h-full grid-cols-1 gap-5 lg:grid-cols-11">
          
          {/* QR Reader and Instructions */}
          <div className="col-span-1 lg:col-span-5">
            <div className={`rounded-lg shadow-lg p-4 ${isScanSuccessful ? 'border-4 border-green-500' : ''}`}>
            <button 
             onClick={toggleQrReader}
             className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
           >
             {isQrReaderActive ? 'Deactivate Camera' : 'Activate Camera'}
           </button>

           {isQrReaderActive && (
             <QrReader
               onResult={(result, error) => {
                 if (!!result) {
                   handleScan(result?.text);
                 }
                 if (!!error) {
                   console.info(error);
                 }
               }}
               style={{ width: '100%' }}
               constraints={{ facingMode: "environment" }}
             />
           )}
              <div className="mt-4 p-2 text-center text-lg font-semibold text-gray-700">
                <p>📸 Point the camera at the QR code to check in</p>
                <p>Scanned data will appear below with a green light for sucess</p>
              </div>
              {data.length > 0 ? (
              <div>
                {data.map((user, index) => (
                  <div key={index} className="mb-4 p-4 border rounded-lg">
                    <div className="flex items-center">
                      <img src={user.profilePic} alt={user.fullName} className="mr-4 h-16 w-16 rounded-full object-cover" />
                      <div>
                        <p className="text-xl font-bold">{user.fullName}</p>
                        <p className="text-md">{user.major}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-lg text-gray-700">👀 Waiting for the first scan...</p>
            )}
            </div>
          </div>

          {/* Scanned Data Display */}
        
          <div className="col-span-1 lg:col-span-6">
                {/* Name Search and Check-in */}
      <div className="col-span-1">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearch}
          className="p-2 border rounded"
        />
        {searchTerm.trim() && filteredUsers.map((user, index) => (
   <div
   key={index}
   className={`mb-4 p-4 border rounded-lg flex items-center transition-all duration-500 ${user.userID === flashingUserId ? 'bg-green-500' : 'bg-white'}`}
 >
    <img src={user.profilePic} alt={user.fullName} className="mr-4 h-16 w-16 rounded-full object-cover" />
    <div>
      <p className="text-lg font-bold">{user.fullName}</p>
      <p className="text-sm">{user.major}</p>
    </div>
    <button 
              onClick={() => handleCheckInClick(user.fullName, user.userID)}
              className="ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                            + Check In
            </button>

  </div>
))}
 

      </div>
            
          </div>
         

        </div>
      </div>

      {/* Right Side Placeholder (for future content) */}
      {/* <div className="col-span-1 3xl:col-span-2">
        Future content or additional components can go here
      </div> */}
    </div>
  );
};


export default CarInterface;
