import NftCard from "components/card/NftCard";
import Banner from "./components/Banner";
import Search from "./components/Search";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";


import SearchTableUsers from "views/admin/main/users/overview/components/SearchTableUsersOverview"
import {columnsDataUsersOverview} from "views/admin/main/users/overview/variables/columnsDataUsersOverview"


import { doc, setDoc } from "firebase/firestore";
import {  AIRecommendation } from "../../../../firebase/firebase-calls";


//show right startups
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from 'react';


//openai


//qrcode





const NftCollection = () => {

  // const transformUserData = (allUsers) => {
  //   return allUsers.map(user => ({
  //     name: [user.fullName, user.profilePic, user.userID],
  //     email: user.email,
  //     major: user.major || 'Undeclared', // fallback to 'Member' if major is not available
  //     // actions: 'Actions', // replace with actual actions if available
  //     linkedIn: user.linkedIn,
  //     networkingGoal: user.networkingGoal,
  //     interests: user.interests,
  //     dedication: user.dedication,
  //   }));
  // };
  // const { allUsers } = useSelector((state) => state.allUsers);
  
  // const transformedData = transformUserData(allUsers);
  

  
  const { allCircles } = useSelector((State) => State.allCircles);
   
  


  return (
    <div className="mt-20 h-full w-full rounded-[20px]">
      <Banner />
      <div className="mt-[70px] w-full">
        {/* <Search /> */}
      </div>

      <h4 className="ml-[23px] mt-8 text-2xl font-bold text-navy-700 dark:text-white">
        Find a team
      </h4>

      {/* <button onClick={handleMatchUsersClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Match Users
      </button>
      <button onClick={handleSubmitUsersClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Submit Users
      </button> */}
<div className="mt-[38px] grid grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
        {allCircles?.length > 0 ? (
          allCircles.map((circles) => (
            <Link 
              key={circles.startUpID}
              className="rounded-lg w-full h-full p-4" // Adjust card padding here
              to={`/admin/nfts/${circles.startUpID}`}
            >
              <NftCard
                bidders={[avatar1, avatar2, avatar3]}
                title={circles.name}
                author={circles.competitionTrack}
                price={circles.competitionTrack}
                image={circles.logo}
              />
            </Link>
          ))
        ) : (
          <div className="h-[30vh] w-full ml-6 overflow-none">
            {/* ... */}
          </div>
        )}
  </div>
      <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 m-5 rounded-lg shadow-md flex flex-col items-center justify-center space-y-4">
        <h2 className="text-2xl font-semibold">🚀 Exciting Features Ahead! 🌟</h2>
        <p className="text-md">
          🤩 <strong>You're just a step away from something amazing!</strong> Our beta features are currently being tested by a select group of users.
        </p>
        <p className="text-md">
          💫 <strong>Stay tuned</strong> and get ready to experience the magic of AI! ✨
        </p>
      </div>
      {/* <SearchTableUsers
          tableData={transformedData}
          columnsData={columnsDataUsersOverview}
      /> */}
    </div>
  );
};

export default NftCollection;
