// import Card from "components/card";
// import InputField from "components/fields/InputField";
// import Centered from "layouts/auth/types/Centered";
// import {React, useState, useEffect} from "react";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
// import { getUser, createProfile } from "../../../firebase/firebase-calls";
// import {toast , Toaster }from "react-hot-toast";
// import image from "../../../assets/svg/nvc-header.jpg"


// // new imports
// import {useNavigate } from "react-router-dom";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// function SignInCenter() {
//   const navigate = useNavigate();

//   //functions for checking if new user
//   const [userData, setUserData] = useState({});
//   const [newUser, setNewUser] = useState(false);

//   const auth = getAuth();
//   const currentUser = auth?.currentUser;      



  
//   const handleSignIn = () => {
//     const provider = new GoogleAuthProvider();
    
//     signInWithPopup(auth, provider)
//     .then((result) => {
//       // This gives you a Google Access Token. You can use it to access the Google API.
//       const credential = GoogleAuthProvider.credentialFromResult(result);
//       const token = credential.accessToken;
//       // The signed-in user info.
//       const user = result.user;
//       console.log("token:", token)
//       console.log(user)
//       localStorage.setItem("authToken", user.accessToken);
//       toast.success("Succesfully logged in!")
      

//       // IdP data available using getAdditionalUserInfo(result)
//       // ...
//     }).catch((error) => {
//       // Handle Errors here.
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       // The email of the user's account used.
//       const email = error.customData.email;
//       // The AuthCredential type that was used.
//       const credential = GoogleAuthProvider.credentialFromError(error);

      
//       // ...
//     });
    
//   }
//   // useEffect(() => {
//   //   if (currentUser && currentUser.uid) {
//   //     getUser(currentUser, setUserData)
//   //       // .then(() => setLoading(false))
//   //       .catch(error => console.error('Error fetching user data:', error));
//   //   } else {
//   //     console.error('No current user or missing UID:', currentUser);
//   //   }
//   // }, [currentUser]);


//   // useEffect(() => {
//   //   if (Object.keys(userData).length > 0) {  // Check if userData has been updated
//   //     console.log("test to see if userData is filled for login: " + userData.userID);
//   //     if (userData.userID) {
//   //       console.log("old user")
//   //       navigate("/admin/nvc");
//   //     }
//   //     else {
//   //       console.log("new user")
//   //       createProfile(currentUser);
//   //       navigate("/admin/main/profile/settings");
//   //     }
//   //   }
    
//   // }, [userData, currentUser, navigate]);  // Re-run this useEffect when userData, currentUser, or navigate changes
  
//   useEffect(() => {
//     if (currentUser && currentUser.uid) {
//       getUser(currentUser, setUserData)
//         .then(userExists => {
//           if (userExists) {
//             console.log("old user");
//             navigate("/admin/nvc");
//           } else {
//             console.log("new user");
//             createProfile(currentUser);
//             navigate("/admin/main/profile/settings");
//           }
//         })
//         .catch(error => console.error('Error fetching user data:', error));
//     } else {
//       console.error('No current user or missing UID:', currentUser);
//     }
//   }, [currentUser, navigate]);  // Now this useEffect only depen

  
//   return (
//     <>
//       <style>
//         {`
//           @keyframes gradientBG {
//             0% {
//               background-position: 0% 50%;
//             }
//             50% {
//               background-position: 100% 50%;
//             }
//             100% {
//               background-position: 0% 50%;
//             }
//           }

//           .animated-gradient {
//             background: linear-gradient(135deg, #868CFF 0%, #4318FF 100%);
//             background-size: 200% 200%;
//             animation: gradientBG 15s ease infinite;
//           }
//         `}
//       </style>
//       <div className="animated-gradient">

//     <Centered
//       bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
//       maincard={
//         <Card extra="max-w-[405px] md:max-w-[700px] md:w-[510px] h-max mx-10 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
//            {/* NVC Header Image */}
//            <img src={image} alt="NVC Header" className="mb-4 w-full" />

//           <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
//             Sign In/Up
//           </h3>
//           <p className="mb-9 ml-1 text-base text-gray-600">
//             Use Google to quickly Sign in/up!
//           </p>
//           <div onClick={() => handleSignIn()} className=" cursor-pointer mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
//             <div className="rounded-full text-xl">
//               <FcGoogle />
//             </div>
//             <button className="text-sm font-medium text-navy-700 dark:text-white">
//               Sign In with Google
//             </button>
//           </div>
//           <div className="text-center font-bold text-purple-600 p-2">
//             <a href="/admin/nvc">Continue as guest</a>
//           </div>

         
//           <Toaster></Toaster>
//         </Card>
//       }
//     />
//           </div>
//     </>

//   );
// }

// export default SignInCenter;
import Card from "components/card";
import InputField from "components/fields/InputField";
import Centered from "layouts/auth/types/Centered";
import {React, useState, useEffect} from "react";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { getUser, createProfile } from "../../../firebase/firebase-calls";
import {toast , Toaster }from "react-hot-toast";
import image from "../../../assets/svg/nvc-header.jpg"


// new imports
import {useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function SignInCenter() {
  const navigate = useNavigate();

  //functions for checking if new user
  const [userData, setUserData] = useState({});
  const [newUser, setNewUser] = useState(false);

  const auth = getAuth();
  const currentUser = auth?.currentUser;      



  
  const handleSignIn = () => {
    const provider = new GoogleAuthProvider();
    
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log("token:", token)
      console.log(user)
      localStorage.setItem("authToken", user.accessToken);
      toast.success("Succesfully logged in!")
      

      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      
      // ...
    });
    
  }
  // useEffect(() => {
  //   if (currentUser && currentUser.uid) {
  //     getUser(currentUser, setUserData)
  //       // .then(() => setLoading(false))
  //       .catch(error => console.error('Error fetching user data:', error));
  //   } else {
  //     console.error('No current user or missing UID:', currentUser);
  //   }
  // }, [currentUser]);


  // useEffect(() => {
  //   if (Object.keys(userData).length > 0) {  // Check if userData has been updated
  //     console.log("test to see if userData is filled for login: " + userData.userID);
  //     if (userData.userID) {
  //       console.log("old user")
  //       navigate("/admin/nvc");
  //     }
  //     else {
  //       console.log("new user")
  //       createProfile(currentUser);
  //       navigate("/admin/main/profile/settings");
  //     }
  //   }
    
  // }, [userData, currentUser, navigate]);  // Re-run this useEffect when userData, currentUser, or navigate changes
  
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      getUser(currentUser, setUserData)
        .then(userExists => {
          if (userExists) {
            console.log("old user");
            navigate("/admin/nvc");
          } else {
            console.log("new user");
            createProfile(currentUser);
            navigate("/admin/main/profile/settings");
          }
        })
        .catch(error => console.error('Error fetching user data:', error));
    } else {
      console.error('No current user or missing UID:', currentUser);
    }
  }, [currentUser, navigate]);  // Now this useEffect only depen

  
  return (
    <Centered
      bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[405px] md:max-w-[700px] md:w-[510px] h-max mx-10 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
           {/* NVC Header Image */}
           <img src={image} alt="NVC Header" className="mb-4 w-full" />

          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Sign In/Up
          </h3>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Use Google to quickly Sign in/up!
          </p>
          <div onClick={() => handleSignIn()} className=" cursor-pointer mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
            <div className="rounded-full text-xl">
              <FcGoogle />
            </div>
            <button className="text-sm font-medium text-navy-700 dark:text-white">
              Sign In with Google
            </button>
          </div>
          <div className="text-center font-bold text-purple-600 p-2">
            <a href="/admin/nvc">Continue as guest</a>
          </div>

         
          <Toaster></Toaster>
        </Card>
      }
    />
  );
}

export default SignInCenter;