import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allRecommendation: [],
};

const allRecommendationSlice = createSlice({
  name: "allRecommendation",
  initialState,
  reducers: {
    recommendationList: (state, action) => {
      state.allRecommendation = action.payload;
    },
  },
});

export const { recommendationList } = allRecommendationSlice.actions;
export default allRecommendationSlice.reducer;
