// components


import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../../firebase/firebase";
import { joinCircle, getCircle, viewCount, textBlast,archiveCircle} from "../../../../firebase/firebase-calls";
import { Link, NavLink } from "react-router-dom";
import { IoMdStar } from "react-icons/io";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Card from "components/card";


const NftPage = () => {
  //Logic

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const options = { month: 'long', day: 'numeric' };

  const { circleID } = useParams();
  const { user } = useSelector((state) => state.user);
  const { allUsers } = useSelector((state) => state.allUsers);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const currentUser = auth?.currentUser;
  const [circleData, setCircleData] = useState([]);
  const [creatorInfo, setCreatorInfo] = useState() 




  useEffect(() => {
    if (circleData?.length == 0) {
      getCircle(circleID, setCircleData);
      // setCreatorInfo(  allUsers.find(user => user.userID === circleData?.creator))
      // console.log("Creator Info: " + creatorInfo)
      setIsLoading(false);
      

      // setTimeout(() => {
      //   viewCount(circleID);
      //   setIsLoading(false);
      // }, 1000); // wait for 0.75 second before calling getCircle
    }
  }, [circleData]);

  const archive = async(e) =>{
    archiveCircle(`${circleID}`);
    navigate("/");
  }

  const handleJoinCircle = async (response) => {
    if (currentUser && circleData) {
      try {
        await joinCircle(currentUser, circleData, response, user); // Assuming 'user' is the user's data you want to pass
        console.log(`User ${currentUser.uid} joined the circle with response: ${response}`);
      } catch (error) {
        console.error("Error joining circle:", error);
        // handle error
      }
    }
  };

  




  return (
//     (!isLoading && (<div className="mt-20 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
//     <div className="grid h-full w-full grid-cols-6 gap-[20px]">
//       <div className="col-span-6 lg:col-span-3">
//         <Banner className="h-[180px] w-[180px]" image={circleData?.logo} />
//         <Description
//           creator={circleData?.creator}
//           functionality={circleData?.functionality}
//           market={circleData?.market}
//         />
//       </div>
//       <p className="text-left font-extrabold mt-4">Team</p>
//       {/* <ul className="mx-auto flex flex-col text-center items-center font-light">
//   {circleData?.memberCount?.map((member, index) => {
//     // Directly finding user information for each member
//     console.log(member)
//     const user = allUsers.find(user => user?.userID === member?.uid);
//     console.log(user)

//     // Check if user was found before rendering
//     if (!user) {
//       return null; // or handle the absence of user info appropriately
//     }

//     return (
//       <div className="rounded-lg pt-1 font-semibold text-left w-full flex flex-row" key={member.uid + index}>
//         <Link to={`/profile/${member?.uid}`} className="flex items-center">
//           <img
//             src={user.profilePic}
//             alt={user.fullName}
//             className="h-7 aspect-square rounded-full"
//           />
//           <p className="text-left font-extrabold">&nbsp;{user.fullName}</p>
//           <p className="text-left font-extrabold">&nbsp;{user.major}</p>

//         </Link>
//       </div>
//     );
//   })}
// </ul> */}


//        {/* Button to join the circle */}
//        <button 
//           onClick={() => handleJoinCircle("Yes")} // Example: User responds with "Yes"
//           className="rounded-lg bg-brand-500 px-4 py-2 text-white hover:bg-brand-600"
//         >
//           Join Circle
//         </button>

//       <div className=" col-span-6 lg:!col-span-3">
//         <div className=" xl:px-16">
//           <Auction
//             name={circleData?.name}
//             track={circleData?.competitionTrack}
//             description={circleData?.description}

//           />
//         </div>

//       </div>
//     </div>
//     {/* NFT card */}

//     <div className="h-full w-full rounded-[20px]">
//       <h4 className="mt-16 xl:mt-7 ml-5 text-2xl font-medium text-navy-700 dark:text-white">
//         Find More Teams On the <a className="text-brand-500 font-bold underline underline-offset-4" href="/admin/nvc">Home</a> Page
//       </h4>

//       {/* 
//       <div className="mt-12 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
//       {allCircles?.length > 0 ? (
//                 allCircles?.map((circles) => (
//                   <Link className="button rounded-lg h-full w-full md:mb-4 shadow-lg"
//                     to={`/admin/nfts/${circles?.startUpID}`}
//                   >
//                     <NftCard
//                       bidders={[avatar1, avatar2, avatar3]} // images for those that have rsvped
//                       title={circles.name} // event title
//                       author={""}
//                       price={circles?.competitionTrack}
//                       image={circles?.logo}
//                     />
//                   </Link>
//                 )))
//                 : (
//                   <div className="h-[30vh] w-full ml-6 overflow-none"><p className="text-sm md:text-md">Find more teams to RSVP for on the <a href="/home" className="underline text-blue-300">home screen</a></p></div>

//                 )
//               }
//       </div>
//       */}
//     </div>
//   </div>))
(!isLoading && (
<div className="mt-3 grid w-full grid-cols-1 gap-5 font-dm lg:grid-cols-11">
<div className="h-full w-full rounded-[20px] lg:col-span-7 4xl:col-span-8">
<Card extra={"w-full mb-5"}>
  <img
    className="w-full max-h-[500px] object-cover rounded-[20px]" // Set the maximum height and cover object fit
    src={circleData?.logo}
    alt="Circle Logo"
  />
</Card>

<Card extra={"w-full !py-6 !pb-16 !px-4 md:!px-10"}>
{/* Header */}
<div className="mb-2 mt-3 flex items-center justify-end">
  <div className="flex items-center gap-3">
    <div className="mt-10 4xl:col-start-2 4xl:col-end-3 4xl:row-start-1 4xl:row-end-2">
  <div className="w-full rounded-xl bg-lightPrimary px-[20px] py-[20px] dark:bg-navy-700">
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        {circleData?.name}
      </h4>
      <div className="mt-[14px] flex items-center gap-[17px]">
        
        <div className="">
          <h5 className="text-base font-bold leading-6 text-navy-700 dark:text-white">
            {circleData?.competitionTrack}
          </h5>
          <p className="text-sm font-medium text-gray-600">
            {circleData?.market}
          </p>
        </div>
      </div>
      <p className="mt-[18px] leading-6 text-navy-700 dark:text-white">
       {circleData?.description}
      </p>
      
    </div>  </div>
   
    {/* arrow button */}
    <div className="flex items-center gap-2">
     
    </div>
  </div>
</div>
{/* tabs */}
<div className="mb-1 flex w-full flex-col items-center rounded-xl xl:mb-3">
  <div className="flex w-full justify-start gap-8 overflow-hidden text-3xl">
  
  </div>
</div>
{/* main text */}
<div className="4xl:grid-cols-[2.95fr 1fr] grid">
  {/* left side */}
  <div className="4xl:col-start-1 4xl:col-end-2 4xl:row-start-1 4xl:row-end-2">
    {/* paragraphs */}
    <div className="mt-10 pr-4 text-lg text-gray-700 dark:text-white">
   
    <p className="text-[18px] font-medium text-navy-700 dark:text-white mb-3">
        How it works
  </p>
      <p className="mb-10 leading-6">
       {circleData?.functionality}
      </p>
      <p className="text-[18px] font-medium text-navy-700 dark:text-white mb-3">
        Roles we are looking for
  </p>
      <p className="mb-10 leading-6">
       {circleData?.roles}
      </p>
      {/* <p className="mb-10 leading-7">
        Your time will pay off by reaching thousands of new customers, and
        building a strong, trustworthy relationship through Instagram will
        skyrocket your brand awareness to a level beyond your
        expectations. You will have the tools to create quality content,
        grow your Instagram followers and market your business to these
        hyper-targeted customers.
      </p>
      <p>
        When making a purchasing decision, people online use your social
        media presence as a measure of the quality, and trustworthiness of
        your business. Nothing speaks trust and quality louder than having
        a thousands of targeted, real, and loving Instagram followers on
        your profile (of which you can contact at any time!) Your profile
        will be professional and compelling and you will be using stories,
        live streaming and all the other new features Instagram releases.
      </p> */}
    </div>

    {/* Rating section */}
    <div className="flex w-full max-w-full flex-col items-center justify-between pt-[75px] md:flex-row">
      {/* left side */}
      
      {/* right side */}
      
    </div>
  </div>
  {/* Instructor */}
  

    {(() => {
      const creatorInfo = allUsers.find(user => user.userID === circleData?.creator);

  const handleBoxClick = () => {
    if (creatorInfo) {
      navigate(`/admin/main/profile/overview/${circleData?.creator}`);
    }
  };

      return creatorInfo ? (
        <>
        <div className="mt-10 4xl:col-start-2 4xl:col-end-3 4xl:row-start-1 4xl:row-end-2" onClick={handleBoxClick}>
  <div className="w-full rounded-xl bg-lightPrimary px-[20px] py-[20px] dark:bg-navy-700">
    <h4 className="text-xl font-bold text-navy-700 dark:text-white">
      About Captain
    </h4>
        <div className="mt-[14px] flex items-center gap-[17px]" >
            <div className="flex h-[52px] w-[52px] items-center justify-center rounded-full">
              <img
                className="h-full w-full rounded-full object-cover"
                src={creatorInfo.profilePic || 'default_profile_pic_url'}
                alt={creatorInfo.fullName}
              />
            </div>
            <div>
              <h5 className="text-base font-bold leading-6 text-navy-700 dark:text-white">
                {creatorInfo.fullName}
              </h5>
              <p className="text-sm font-medium text-gray-600">
                {creatorInfo.major}
              </p>
            </div>
          </div>
          <p className="mt-[18px] leading-6 text-navy-700 dark:text-white">
            {creatorInfo.bio}
          </p>
          <button
              className="mt-4 w-full rounded-full bg-[#0077b5] py-2 text-base font-medium text-white transition duration-200 hover:bg-[#006097] active:bg-[#004c70]"
              onClick={(e) => {
                e.stopPropagation();
                window.open(creatorInfo.linkedIn, '_blank');
              }}
            >
              LinkedIn
            </button>
            </div>  </div>


        </>
      ) : (
        <p>No creator information available.</p>
      );
    })()}
   

</div>
</Card>
</div>
</div>

    
  )))
};

export default NftPage;
