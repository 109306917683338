import { 
    MdHome, 
    MdSettings, 
    MdPeople, 
    MdLocalLibrary, 
    MdAddCircle,
    MdLanguage 
} from "react-icons/md";

  
  // Admin Imports
  import DashboardsDefault from "views/admin/dashboards/default";
  import DashboardsRTLDefault from "views/admin/dashboards/rtl";
  import DashboardsCarInterface from "views/admin/dashboards/carInterface";
  import DashboardsSmartHome from "views/admin/dashboards/smartHome";
  
  // NFT Imports
  import NFTMarketplace from "views/admin/nfts/marketplace";
  import NFTPage from "views/admin/nfts/page";
  import NFTCollection from "views/admin/nfts/collection";
  import NFTProfile from "views/admin/nfts/profile";
  
  // Main Imports
  import AccountBilling from "views/admin/main/account/billing";
  import AccountApplications from "views/admin/main/account/application";
  import AccountInvoice from "views/admin/main/account/invoice";
  import AccountSettings from "views/admin/main/account/settings";
  import AccountAllCourses from "views/admin/main/account/courses";
  import AccountCoursePage from "views/admin/main/account/coursePage";
  
  import UserNew from "views/admin/main/users/newUser";
  import UsersOverview from "views/admin/main/users/overview";
  import Recommendation from "views/admin/main/users/recommendation";

  import { AIRecommendation } from "views/admin/main/users/overview";
  import UsersReports from "views/admin/main/users/reports";
  
  import ProfileSettings from "views/admin/main/profile/settings";
  import ProfileOverview from "views/admin/main/profile/overview";
  import ProfileNewsfeed from "views/admin/main/profile/newsfeed";
  
  import ApplicationsKanban from "views/admin/main/applications/kanban";
  import ApplicationsDataTables from "views/admin/main/applications/dataTables";
  import ApplicationsCalendar from "views/admin/main/applications/Calendar";
  
  import EcommerceNewProduct from "views/admin/main/ecommerce/newProduct";
  import EcommerceProductSettings from "views/admin/main/ecommerce/settingsProduct";
  import EcommerceProductPage from "views/admin/main/ecommerce/productPage";
  import EcommerceOrderList from "views/admin/main/ecommerce/orderList";
  import EcommerceOrderDetails from "views/admin/main/ecommerce/orderDetails";
  import EcommerceReferrals from "views/admin/main/ecommerce/referrals";
  
  // Others
  import OthersNotifications from "views/admin/main/others/notifications";
  //import OthersPricing from 'views/admin/main/others/pricing';
  import OthersPricing from "views/admin/main/others/pricing";
  import OthersError from "views/admin/main/others/404";
  import Buttons from "views/admin/main/others/buttons";
  import Messages from "views/admin/main/others/messages";
  
  // Auth Imports
  import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter.jsx";
  import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault.jsx";
  import LockCentered from "views/auth/lock/LockCenter.jsx";
  import LockDefault from "views/auth/lock/LockDefault.jsx";
  import SignInCentered from "views/auth/signIn/SignInCenter.jsx";
  import SignInDefault from "views/auth/signIn/SignInDefault.jsx";
  import SignUpCentered from "views/auth/signUp/SignUpCenter.jsx";
  import SignUpDefault from "views/auth/signUp/SignUpDefault.jsx";
  import VerificationCentered from "views/auth/verification/VerificationCenter.jsx";
  import VerificationDefault from "views/auth/verification/VerificationDefault.jsx";
  
//   const routes = [
//     // ... other routes

//     // Setting /admin/nvc as home page
//     {
//         name: "Home",
//         layout: "/admin",
//         path: "/nvc",
//         component: <NFTCollection />,
//     },

//     // Link to external Resources
   
//     // Who signed up
//     {
//         name: "Who Signed Up",
//         layout: "/admin",
//         path: "/main/users/users-overview",
//         component: <UsersOverview />,
//     },

//     // Profile settings
//     {
//         name: "Profile",
//         layout: "/admin",
//         path: "/main/profile/settings",
//         component: <ProfileSettings />,
//     },

//     // // NVC official website
//     // {
//     //     name: "NVC Official Website",
//     //     layout: "/admin",
//     //     path: "/nvc-official-website",
//     //     component: () => { window.location.href = 'https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html'; return null; },
//     // },
//     // {
//     //     name: "Resources",
//     //     layout: "/admin",
//     //     path: "/resources",
//     //     component: () => { window.location.href = 'https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#resources'; return null; },
//     // },

const routes = [
    // ... other routes
    {
        name: "Home",
        layout: "/admin",
        path: "/nvc",
        component: <NFTCollection />,
        icon: <MdHome />,
    },
    {
        name: "AI Networking",
        layout: "/admin",
        path: "/main/users/Recommendation",
        component: <Recommendation />,
        icon: <MdPeople />,
    },
    
    // {
    //     name: "Resources",
    //     layout: "/admin",
    //     path: "https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#resources",
    //     component: () => { window.location.href = 'https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#resources'; return null; },
    //     icon: <MdLocalLibrary />,
    //     isExternal: true, // indicate this is an external link
    // },
    
    {
        name: "Profile/QR Code",
        layout: "/admin",
        path: "/main/profile/settings",
        component: <ProfileSettings />,
        icon: <MdSettings />,
    },

    {
        name: "Create/Manage Startup",
        layout: "/admin",
        path: "/main/team",
        component: <EcommerceProductSettings />,
        icon: <MdAddCircle/>

      },
      {
        name: "NVC Official Website-Leave website",
        layout: "/admin",
        path: "https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html",
        component: () => { window.location.href = 'https://merage.uci.edu/research-faculty/centers/innovation-entrepreneurship/new-venture-competiton.html#resources'; return null; },
        icon: <MdLanguage />,
        isExternal: true, // indicate this is an external link
    },
    // ... other routes
];

export default routes;
